import React, {useEffect} from 'react';

import SimpleCardContainer from "../../ui/SimpleCardContainer";
import {useApi} from "../../../providers/ApiProvider";
import {Link} from "react-router-dom";
import TableWrapper from "../../ui/TableWrapper";
import TableLoading from "../../ui/TableLoading";
import {useNotifications} from "../../../providers/NotificationsProvider";
import {useAuth} from "../../../providers/AuthProvider";
import ProjectTasksModel from "../../../models/Projects/ProjectTasksModel";
import {useMeta} from "../../../providers/MetaProvider";
import {IconPencil, IconPlus, IconTrash} from "@tabler/icons-react";
import Grades from "../../../helpers/Grades";
import SectionNavHeader from "../../layout/SectionNavHeader";

export default function ProjectTasksList({}) {
    const apiContext = useApi();
    const authContext = useAuth();
    const projectEssentials = authContext.project;
    const notificationsContext = useNotifications();
    const metaContext = useMeta();
    const projectTasksModel = new ProjectTasksModel(apiContext.api, projectEssentials.id);

    const [dataItems, setDataItems] = React.useState({});
    const [loading, setLoading] = React.useState('general');


    const syncData = () => {
        setLoading('general');

        projectTasksModel.list().then((response) => {
            if (response !== false) {
                setDataItems(response.data);
            }
        }).finally(() => {
            setLoading('');
        });
    }


    const onDelete = (pk) => {
        if (window.confirm('Ви впевнені, що хочете видалити це завдання?')) {
            setLoading('delete-'+pk);

            projectTasksModel.delete(pk).then((response) => {
                if (response !== false) {
                    notificationsContext.notify('Завдання успішно видалено', 'success');

                    syncData();
                }
            }).finally(() => {
                setLoading('');
            });
        }
    }


    useEffect(() => {
        metaContext.update({
            title: 'Завдання до проєкту',
        });

        syncData();
    }, []);


    return (<>
        <nav className="section-nav mb-0 pt-0">
            <SectionNavHeader/>
        </nav>

        <SimpleCardContainer title={'Завдання до проєкту'}>
            <div className="d-flex justify-content-end my-3">
                <Link to={'/p/tasks/create'} className={'btn btn-success'}><IconPlus size={20} stroke={2} className="me-2"/>Додати нове завдання</Link>
            </div>
            <TableWrapper header={[
                {'label': 'Дата завершення', 'cellStyle': {minWidth: '40%'}},
                {'label': 'Статус', 'cellStyle': {width: '120px', 'textAlign': 'center'}},
                {'label': '', 'cellStyle': {width: '180px'}},
            ]}>
                {loading ? (<TableLoading loading={loading} columns={3}/>) : dataItems.map((item, index) => {
                    return (
                        <tr key={index} className={(item.status === 'active') ? '' : 'row-passive'}>
                            <td>
                                <h6 className="fs-4 fw-semibold mb-1">
                                    <a href={'/p/tasks/' + item.id} className={'text-primary'}>{Grades.getDateSinceFormatted(item.date_for)}</a>
                                </h6>
                                {item.description ? (<span className={'text-muted'}>{item.description}</span>) : ''}
                            </td>
                            <td className={'text-center'}>{(item.status === 'active') ? (<i className={'ti ti-checks fs-4'}></i>) : ('')}</td>
                            <td style={{width: '180px'}}>
                                <div className="d-flex align-items-center justify-content-end gap-3">
                                    <Link to={'/p/tasks/' + item.id} className={'btn btn-light-primary btn-circle d-inline-flex'}>
                                        <IconPencil size={20} stroke={2} className="text-primary"/>
                                    </Link>
                                    <button type="button"
                                            className={'btn btn-light-danger btn-circle d-inline-flex'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                onDelete(item.id)
                                            }}>
                                        <IconTrash size={20} stroke={2} className="text-danger"/>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </TableWrapper>
        </SimpleCardContainer>
    </>);
}