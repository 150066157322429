import React from "react";
import {IconTag} from "@tabler/icons-react";


export default function BadgePublicationSales({children, className = '', ...props}) {

    return (
        <div className={'badge badge-publication-sales ' + className} {...props}>
            <IconTag size={17} stroke={2} />
            Продажна
        </div>
    );
}