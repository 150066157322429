import React, {useEffect} from "react";
import imgPlatformStories from "../../../../assets/img/upgraded/instagram-stories.svg";
import imgPlatformFacebook from "../../../../assets/img/upgraded/fb.svg";
import imgPlatformInstagram from "../../../../assets/img/upgraded/ig.svg";
import imgBlank from "../../../../assets/img/blank.png";
import {
    IconArrowNarrowRight,
    IconCircleXFilled,
    IconEye,
    IconMovie,
    IconPhoto, IconPhotoVideo,
    IconSquareCheckFilled, IconSquareRoundedCheck,
    IconSquareRoundedCheckFilled,
    IconX
} from "@tabler/icons-react";
import ProgressBarStepped from "../../../ui/ProgressBarStepped/ProgressBarStepped";
import {Link, useParams} from "react-router-dom";
import ButtonWithLoading from "../../../layout/ui/ButtonWithLoading";
import dayjs from "dayjs";


export default function PublicationsListTabledPlatforms({publication = {}, onDeletePublication}) {
    const {planId} = useParams();

    const [mediaTypes, setMediaTypes] = React.useState({
        photo: 0,
        video: 0,
    });
    const [deleteLoading, setDeleteLoading] = React.useState(0);


    const recalculateMediaTypes = () => {
        const newMediaTypes = {
            photo: 0,
            video: 0,
        };

        publication.media.forEach(version => {
            if (version.media_type === 'image') {
                newMediaTypes.photo++;
            }
            if (version.media_type === 'video') {
                newMediaTypes.video++;
            }
        });

        setMediaTypes(newMediaTypes);
    }


    useEffect(() => {
        recalculateMediaTypes();
    }, [publication]);


    const onDelete = (pk) => {
        setDeleteLoading(pk);

        onDeletePublication(pk).finally(() => {
            setDeleteLoading(0);
        });
    }


    const getPlatformIcon = () => {
        switch (publication.platform) {
            case 'instagram':
                return imgPlatformInstagram;
            case 'facebook':
                return imgPlatformFacebook;
            case 'stories':
                return imgPlatformStories;
            default:
                return imgBlank;
        }
    }


    const getTime = () => {
        const date = dayjs(publication.scheduled_for).locale('uk');
        return date.format('HH:mm');
    }


    const getStatusIndex = () => {
        switch (publication.status) {
            case 'draft':
                return 1;
            case 'under_review':
                return 2;
            case 'scheduled':
                return 3;
            case 'published':
                return 4;
            default:
                return 0;
        }
    }


    return (
        <div className="publications-list-tabled-platforms">
            <div><img src={getPlatformIcon()} alt={publication.platform+' icon'} className="img-icon" /></div>
            <div>{getTime()}</div>
            <div>
                {mediaTypes.photo && !mediaTypes.video ? (
                    <><IconPhoto size={24} stroke={2} className="me-1" />&times;{mediaTypes.photo}</>
                ) : ''}
                {!mediaTypes.photo && mediaTypes.video ? (
                    <><IconMovie size={24} stroke={2} className={'me-1'} />&times;{mediaTypes.video}</>
                ) : ''}
                {mediaTypes.photo && mediaTypes.video ? (
                    <><IconPhotoVideo size={24} stroke={2} className={'me-1'} />&times;{mediaTypes.video+mediaTypes.photo}</>
                ) : ''}
                {!mediaTypes.photo && !mediaTypes.video ? (
                    <IconCircleXFilled size={24} stroke={2} className="text-danger"/>
                ) : ''}
            </div>
            <div>
                {publication.platform === 'stories' ? (
                    <IconSquareRoundedCheck size={24} stroke={2} className="text-body-tertiary"/>
                ) : (
                    publication.latestVersion && publication.latestVersion.text ? (
                        <IconSquareRoundedCheckFilled size={24} stroke={2} className="text-success"/>
                    ) : (
                        <IconCircleXFilled size={24} stroke={2} className="text-danger"/>
                    )
                )}
            </div>
            <div className="status-field">
                <ProgressBarStepped
                    total={4}
                    progress={getStatusIndex()}
                />
                {publication.statuses[publication.status]}
            </div>
            <div>
                <div className="d-none d-lg-inline">
                    <Link to={'/'+['p', planId, publication.id].join('/')} className="btn btn-sm btn-light-primary text-primary">Переглянути</Link>
                </div>
                <div className="d-inline d-lg-none">
                    <Link to={'/'+['p', planId, publication.id].join('/')} className="btn btn-sm btn-icon btn-light-primary text-primary">
                        <IconArrowNarrowRight size={15} stroke={2}/>
                    </Link>
                </div>

                <ButtonWithLoading
                    className="btn btn-sm btn-icon btn-light-danger text-danger ms-2"
                    spinnerClassName="spinner-border spinner-border-sm"
                    iconClassName="d-none"
                    label={(deleteLoading !== publication.id) ? (<IconX size={11} stroke={4}/>) : ''}
                    onClick={() => onDelete(publication.id)}
                    loading={deleteLoading === publication.id}
                    noOriginalClassName={true}
                    noOriginalSpinnerClassName={true}
                    noOriginalIconClassName={true}
                />
            </div>
        </div>
    );
}