import BaseCrudModel from "../BaseCrudModel";

/**
 * Publication Media model
 *
 * @param {ApiHelper} api
 */
export default class PublicationMediaModel extends BaseCrudModel {
    baseUrl = '/publications/{%PUBLICATION_ID%}/media';

    constructor(api, publicationId) {
        super(api);
        this.baseUrl = this.baseUrl.replace('{%PUBLICATION_ID%}', publicationId);
    }


    async updateOrder(mediaIds, errorHandler) {
        const url = this.baseUrl + '/update-order';

        const response = await this.api.postRequest(url, {items: mediaIds});

        return this.processResponse(response, errorHandler);
    }


    async crop(mediaId, cropInfo, errorHandler) {
        const url = this.baseUrl + '/' + mediaId + '/crop';

        const response = await this.api.postRequest(url, cropInfo);

        return this.processResponse(response, errorHandler);
    }


    async makePreview(mediaId, frameInfo, errorHandler) {
        const url = this.baseUrl + '/' + mediaId + '/make-preview';

        const response = await this.api.postRequest(url, frameInfo);

        return this.processResponse(response, errorHandler);
    }
}
