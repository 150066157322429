import React, {useEffect} from "react";

import {useParams} from "react-router-dom";
import PublicationEditForm from "../edit/PublicationEditForm";
import PublicationPreview from "./preview/PublicationPreview";
import PublicationEditMedia from "../edit/PublicationEditMedia";
import PublicationEditCaption from "../edit/PublicationEditCaption";
import {useApi} from "../../../providers/ApiProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsModel from "../../../models/Projects/ProjectsModel";
import PublicationPlansModel from "../../../models/Publications/PublicationPlansModel";
import PublicationsModel from "../../../models/Publications/PublicationsModel";
import PublicationVersionsModel from "../../../models/Publications/PublicationVersionsModel";
import SectionNav from "../../layout/SectionNav";

import './PublicationEdit.css';
import {IconExclamationCircle, IconPhoto, IconTextCaption} from "@tabler/icons-react";
import PublicationEditHeader from "../header/PublicationEditHeader";
import PublicationEditStatus from "../status/PublicationEditStatus";
import Grades from "../../../helpers/Grades";
import {useMeta} from "../../../providers/MetaProvider";


export default function PublicationEdit({}) {
    const {planId, publicationId} = useParams();
    const apiContext = useApi();
    const metaContext = useMeta();
    const notificationsContext = useNotifications();
    const projectsModel = new ProjectsModel(apiContext.api);
    const publicationPlansModel = new PublicationPlansModel(apiContext.api);
    const publicationsModel = new PublicationsModel(apiContext.api);
    const publicationVersionsModel = new PublicationVersionsModel(apiContext.api, publicationId);

    const [loading, setLoading] = React.useState('general');
    const [captionEditMode, setCaptionEditMode] = React.useState(false);
    const [publication, setPublication] = React.useState({});


    useEffect(() => {
        getData();
    }, []);


    const getData = () => {
        setLoading('general');

        publicationsModel.view(publicationId).then((response) => {
            if (response !== false) {
                setPublication(response);

                const pageTitle = 'Публікація в ' + response.platforms[response.platform] + ' від ' + Grades.getDateSinceFormatted(response.scheduled_for);
                metaContext.update({title: pageTitle});
            }
        }).finally(() => {
            setLoading('');
        });
    }


    const onMediaChanged = () => {
        getData();
    }


    const onCaptionChanged = (newCaption) => {
        setPublication({...publication, latestVersion: newCaption});
    }


    return publication ? (<>
        <SectionNav plan={publication.plan} />

        <div className="publication-edit">
            <PublicationEditHeader publication={publication}/>

            <PublicationEditStatus status={publication.status}/>

            <div className="publication-edit-contents">
                <PublicationPreview publication={publication}/>

                <div className="publication-edit-contents-working-area d-flex align-items-start">
                    <div className="nav nav-pills" role="tablist" aria-orientation="vertical">
                        <button className="nav-link active" id="publication-edit-media-tab" data-bs-toggle="pill" data-bs-target="#publication-edit-media"
                                type="button" role="tab"
                                aria-controls="publication-edit-media" aria-selected="true"><IconPhoto size={24} stroke={2}/>
                        </button>
                        <button className={'nav-link'+(publication.platform === 'stories' ? ' opacity-25' : '')} id="publication-edit-caption-tab" data-bs-toggle="pill" data-bs-target="#publication-edit-caption"
                                type="button" role="tab"
                                aria-controls="publication-edit-caption" aria-selected="false"><IconTextCaption size={24} stroke={2}/>
                        </button>
                    </div>
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="publication-edit-media" role="tabpanel" aria-labelledby="publication-edit-media-tab"
                             tabIndex="0">
                            <PublicationEditMedia
                                publication={publication}
                                onMediaChanged={onMediaChanged}
                            />
                        </div>
                        <div className="tab-pane fade" id="publication-edit-caption" role="tabpanel" aria-labelledby="publication-edit-caption-tab"
                             tabIndex="0">
                            <PublicationEditCaption
                                publication={publication}
                                onCaptionChanged={onCaptionChanged}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>) : '';
}