import React, { useEffect } from 'react';

import SimpleCardContainer from "../../ui/SimpleCardContainer";
import { useApi } from "../../../providers/ApiProvider";
import { useBreadcrumbs } from "../../../providers/BreadcrumbsProvider";
import { Link, useParams } from "react-router-dom";
import { useNotifications } from "../../../providers/NotificationsProvider";
import BrandsModel from "../../../models/Projects/ProjectTasksModel";
import ProjectTasksForm from "./ProjectTasksForm";
import { useAuth } from "../../../providers/AuthProvider";
import { useMeta } from "../../../providers/MetaProvider";
import ProjectTasksModel from "../../../models/Projects/ProjectTasksModel";
import Grades from "../../../helpers/Grades";
import { IconArrowNarrowLeft, IconArrowNarrowRight, IconInfoSquareRounded, IconPencil } from "@tabler/icons-react";
import SimpleBar from "simplebar-react";
import LineChartPlan from "../../ui/charts/LineChartPlan";
import LineAndPointChart from "../../ui/charts/LineAndPointChart";

export default function ProjectTasksView({ }) {
    const { taskId } = useParams();
    const apiContext = useApi();
    const authContext = useAuth();
    const projectEssentials = authContext.project;
    const notificationsContext = useNotifications();
    const metaContext = useMeta();
    const projectTasksModel = new ProjectTasksModel(apiContext.api, projectEssentials.id);


    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState({});
    const [mobileActiveChartIndex, setMobileActiveChartIndex] = React.useState(0);
    const totalMobileCharts = 2;


    useEffect(() => {
        loadData();
    }, []);


    const loadData = () => {
        setLoading(true);

        projectTasksModel.view(taskId).then((response) => {
            if (response !== false) {
                setData(response);

                metaContext.update({
                    title: 'Завдання до ' + Grades.getDateSinceFormatted(response.date_for),
                });
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const onClickForward = (e) => {
        e.preventDefault();
        let nextIndex = mobileActiveChartIndex + 1;
        if (nextIndex >= totalMobileCharts) {
            nextIndex = 0;
        }

        setMobileActiveChartIndex(nextIndex);
    }
    const onClickBackward = (e) => {
        e.preventDefault();
        let nextIndex = mobileActiveChartIndex - 1;
        if (nextIndex < 0) {
            nextIndex = totalMobileCharts - 1;
        }

        setMobileActiveChartIndex(nextIndex);
    }


    const level = 8;
    const visibilityDummyDataByDate = {
        byDate: [{ x: 1691625600000, y: 5 }, { x: 1691712000000, y: 7.7 }, { x: 1691798400000, y: 10 }, { x: 1691884800000, y: 2 }, { x: 1691971200000, y: 7.55 }, { x: 1692057600000, y: 5.35 },
        { x: 1692144000000, y: 3.0 }, { x: 1692230400000, y: 3.5 }, { x: 1692316800000, y: 6.75 }, { x: 1692403200000, y: 4.5 }, { x: 1692489600000, y: 4.10 }, { x: 1692576000000, y: 5.5 },
        { x: 1692662400000, y: 6.0 }, { x: 1692748800000, y: 6.57 }, { x: 1692835200000, y: 3.05 }, { x: 1692921600000, y: 7.5 }, { x: 1693008000000, y: 1.0 }, { x: 1693094400000, y: 8.5 },
        { x: 1693180800000, y: 9.0 }, { x: 1693267200000, y: 9.5 }],
    };

    const efficiencyDummyData = {
        current: [{ x: 0, y: 0 }, { x: 5, y: 0.3 }, { x: 10, y: 0.7 }, { x: 15, y: 1.35 }, { x: 20, y: 1.9 }, { x: 25, y: 2.7 }, { x: 30, y: 2.9 }, { x: 35, y: 4.1 }, {
            x: 40,
            y: 4.9
        }, { x: 45, y: 6.4 }, { x: 50, y: 7.5 }, { x: 55, y: 9.1 }, { x: 60, y: 9.5 }],
        average: [{ x: 0, y: 0 }, { x: 5, y: 0.7 }, { x: 10, y: 1.6 }, { x: 15, y: 3.1 }, { x: 20, y: 4.2 }, { x: 25, y: 4.9 }, { x: 30, y: 5.8 }, { x: 35, y: 6 }, {
            x: 40,
            y: 8.5
        }, { x: 45, y: 8.9 }, { x: 50, y: 9.9 }, { x: 55, y: 10.5 }, { x: 60, y: 11.2 }],
        best: [{ x: 0, y: 0 }, { x: 5, y: 1 }, { x: 10, y: 2 }, { x: 15, y: 4 }, { x: 20, y: 5.2 }, { x: 25, y: 7 }, { x: 30, y: 9 }, { x: 35, y: 10 }, { x: 40, y: 11 }, {
            x: 45,
            y: 11.5
        }, { x: 50, y: 12.9 }, { x: 55, y: 13.5 }, { x: 60, y: 17.9 }],
        expected: [{ x: 0, y: 0 }, { x: 60, y: 8 }],
    }


    return loading ? '' : (<>
        <div className="d-flex mb-st">
            <Link to={'/p/tasks'} className="btn btn-light-info text-info">
                <IconArrowNarrowLeft size={20} stroke={2} className="me-2" />
                Назад до всіх завдань
            </Link>
        </div>

        <div className="row block-gutter">
            <div className="col-xl-5">
                <div className="d-flex flex-column block-gap-single">
                    <div className="">
                        <div className="d-inline-flex flex-row align-items-start justify-content-between gap-4">
                            <h1 className="h3">Завдання до {Grades.getDateSinceFormatted(data.date_for)}</h1>

                            <div className="d-flex flex-row align-items-center justify-content-end">
                                <Link to={'/' + ['p', 'tasks', data.id, 'edit'].join('/')} className=""><IconPencil size={20} stroke={2} /></Link>
                            </div>
                        </div>
                        {data.description && (<p className="lead">{data.description}</p>)}

                        {data.discipline_rules ? (
                            <div>
                                <h5 className="mt-3 mb-0">Формальні вимоги</h5>

                                <h6 className="mt-2 mb-1">Кількість публікацій у плані:</h6>
                                <ul>
                                    <li>Instagram: {data.discipline_rules.instagram.posts_number} шт</li>
                                    <li>Facebook: {data.discipline_rules.facebook.posts_number} шт</li>
                                    <li>Історії Instagram: {data.discipline_rules.stories.posts_number} шт</li>
                                </ul>
                            </div>
                        ) : ''}
                    </div>

                    <div className="d-flex flex-column gap-3">
                        <h5 className="m-0">Плани, залучені до виконання завдання</h5>
                        <div className="d-flex flex-column gap-2">
                            <Link to={'/'}>від 2 грудня</Link>
                            <Link to={'/'}>від 9 грудня</Link>
                            <Link to={'/'}>від 16 грудня</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-7 opacity-25">
                <div className="d-flex flex-column">
                    <SimpleBar autoHide={true}>
                        <div className="d-flex mb-st">
                            <button className="btn btn-sm btn-light-primary text-primary">Instagram</button>
                            <button className="btn btn-sm text-primary">Facebook</button>
                            <button className="btn btn-sm text-primary">Історії Instagram</button>
                        </div>
                    </SimpleBar>


                    <div className="row block-gutter-lg">
                        <div className="col-xl-6">
                            <div className={'line-chart-noted' + (mobileActiveChartIndex === 0 ? ' active' : '')}>
                                <div className="line-chart-noted-title">
                                    <h3>
                                        <div className="title-text">
                                            Видимість для публікацій
                                            <a href="#"><IconInfoSquareRounded size={20} stroke={2} /></a>
                                        </div>
                                        <div className="title-tools">
                                            <button className="btn btn-sm p-0" onClick={onClickForward}><IconArrowNarrowLeft size={20} stroke={2} /></button>
                                            <button className="btn btn-sm p-0" onClick={onClickBackward}><IconArrowNarrowRight size={20} stroke={2} />
                                            </button>
                                        </div>
                                    </h3>

                                    <p className="chart-description">Відсоток охопленої аудиторії відносно кількості підписників.</p>
                                </div>

                                <LineAndPointChart
                                    data={visibilityDummyDataByDate}
                                    level={level}
                                />
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className={'line-chart-noted' + (mobileActiveChartIndex === 1 ? ' active' : '')}>
                                <div className="line-chart-noted-title">
                                    <h3>
                                        <div className="title-text">
                                            Ефективність для публікацій
                                            <a href="#"><IconInfoSquareRounded size={20} stroke={2} /></a>
                                        </div>
                                        <div className="title-tools">
                                            <button className="btn btn-sm p-0" onClick={onClickForward}><IconArrowNarrowLeft size={20} stroke={2} /></button>
                                            <button className="btn btn-sm p-0" onClick={onClickBackward}><IconArrowNarrowRight size={20} stroke={2} />
                                            </button>
                                        </div>
                                    </h3>

                                    <p className="chart-description">Відсоток взаємодії з публікаціями відносно охопленої аудиторії.</p>
                                </div>

                                <LineChartPlan
                                    data={efficiencyDummyData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}
