import React from "react";

import './ProgressBarStepped.css';

export default function ProgressBarStepped({total, progress, label}) {
    return (
        <div className="progress-bar-stepped">
            {Array.from({length: total}, (_, i) => {
                return (
                    <div key={i} className={`progress-bar-stepped__step ${i < progress ? 'progress-bar-stepped__step--active' : ''}`}></div>
                );
            })}
            {label ? (
            <div className="progress-bar-stepped-label">
                {label}
            </div>
            ) : ''}
        </div>
    );
}