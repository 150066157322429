import * as React from "react";
import {Navigate, useLocation, useNavigate} from "react-router-dom";


const userStructure = {
    id: null,
    name: null,
    email: null,
    type: null,
    access_token: null,
    access_token_expires_at: null,
    refresh_token: null,
    refresh_token_expires_at: null,
    organization_id: null,
    project_id: null,
};

const projectStructure = {
    id: null,
    title: null,
    logo: null,
}

const authStructure = {
    user: userStructure,
    project: projectStructure,
    setProject: () => {},
    login: () => {},
    logout: () => {},
    loginPath: "/auth/login"
}

const AuthContext = React.createContext(authStructure);


function AuthProvider({children}) {
    let initialUser = userStructure,
        initialProject = projectStructure,
        storedUser = localStorage.getItem('user'),
        storedProject = localStorage.getItem('project');

    if (storedUser) {
        initialUser = JSON.parse(storedUser);
    }
    if (storedProject) {
        initialProject = JSON.parse(storedProject);
    }

    let [user, setUser] = React.useState(initialUser);
    let [project, setProject] = React.useState(initialProject);


    let login = (newUser) => {
        setUser(newUser);

        localStorage.setItem('user', JSON.stringify(newUser));
        localStorage.setItem('access_token', newUser.access_token);
        localStorage.setItem('access_token_expires_at', newUser.access_token_expires_at);
        localStorage.setItem('refresh_token', newUser.refresh_token);
        localStorage.setItem('refresh_token_expires_at', newUser.refresh_token_expires_at);
    };


    let logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('project');
        localStorage.removeItem('access_token');
        localStorage.removeItem('access_token_expires_at');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('refresh_token_expires_at');
        setUser(null);
        setProject(null);
    };


    let setProjectInternal = (projectData) => {
        let newProject = {...project};
        Object.keys(projectStructure).forEach((key) => {
            if (projectData[key]) {
                newProject[key] = projectData[key];
            } else {
                newProject[key] = null;
            }
        });
        setProject(newProject);
        localStorage.setItem('project', JSON.stringify(newProject));

        window.location.href = '/';
    }


    let value = JSON.parse(JSON.stringify(authStructure));
    value.user = user;
    value.project = project;
    value.login = login;
    value.logout = logout;
    value.setProject = setProjectInternal;

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}


function useAuth() {
    return React.useContext(AuthContext);
}


function RequireAuth({children}) {
    let auth = useAuth();
    let location = useLocation();

    let sessionInvalid = !auth.user || !auth.user.access_token,
        sessionRecoverable = false;
    if (auth.user.access_token_expires_at && auth.user.access_token_expires_at < Math.floor(Date.now() / 1000)) {
        sessionInvalid = true;
    }

    if (auth.user.refresh_token && auth.user.refresh_token_expires_at && auth.user.refresh_token_expires_at > Math.floor(Date.now() / 1000)) {
        sessionRecoverable = true;
    }

    if (sessionInvalid && !sessionRecoverable) {
        return <Navigate to={auth.loginPath} state={{from: location}} replace/>;
    }

    return (
        <>
            {children}
        </>
    );
}


function RequireProject({children}) {
    let auth = useAuth();
    let location = useLocation();

    if (!auth.project || !auth.project.id) {
        return <Navigate to="/projects" state={{from: location}} replace/>;
    }

    return (
        <>
            {children}
        </>
    );
}


export {AuthProvider, useAuth, RequireAuth, RequireProject, userStructure, projectStructure};