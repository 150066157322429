import React, {useEffect} from 'react';

import {useApi} from "../../../providers/ApiProvider";
import {useAuth} from "../../../providers/AuthProvider";
import {Link, useNavigate} from "react-router-dom";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsModel from "../../../models/Projects/ProjectsModel";
import {IconArrowNarrowRight, IconPencil, IconPlus, IconTrash} from "@tabler/icons-react";

import './Projects.css';

export default function ProjectsList({}) {
    let navigate = useNavigate();
    const breadcrumbs = useBreadcrumbs();
    const authContext = useAuth();
    const apiContext = useApi();
    const notificationsContext = useNotifications();
    const projectsModel = new ProjectsModel(apiContext.api);

    const [dataItems, setDataItems] = React.useState({});
    const [loading, setLoading] = React.useState(true);


    const syncData = () => {
        setLoading(true);

        projectsModel.list().then((response) => {
            if (response !== false) {
                setDataItems(response.data);
                setLoading(false);
            }
        });
    }


    const onDelete = (pk) => {
        if (window.confirm('Ви впевнені, що хочете видалити цей проєкт?')) {
            setLoading(true);

            projectsModel.delete(pk).then((response) => {
                if (response !== false) {
                    notificationsContext.notify('Проєкт успішно видалено', 'success');

                    syncData();
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    }


    useEffect(() => {
        breadcrumbs.update({
            title: null,
            breadcrumbs: []
        });

        document.title = 'Проєкти | Likengale';

        syncData();
    }, []);


    const onProjectSelect = (e, pk) => {
        e.preventDefault();
        e.stopPropagation();

        setLoading(true);

        projectsModel.view(pk).then((response) => {
            if (response !== false) {
                authContext.setProject(response);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    return (<>
        <h2>Мої проєкти</h2>

        <div className="d-flex justify-content-end mb-3">
            <Link to={'/projects/create'} className={'btn btn-success'}><IconPlus size={20} stroke={2} className="me-2" />Додати новий проєкт</Link>
        </div>

        {loading ? ('...') : dataItems.map((item, index) => {
            return (
                <div key={index} className={'project-card ' + (item.is_active ? '' : 'opacity-25')} onClick={(e) => onProjectSelect(e, item.id)}>
                    <div className="d-flex gap-2 align-items-center">
                        {item.logo ? (
                        <img src={item.logo} alt={item.title} style={{width: '42px'}} />
                        ) : null}
                        <div>
                            <h6 className="fs-4 fw-semibold mb-0 d-flex align-items-center gap-4">
                                {item.title}

                                <div className="d-flex align-items-center justify-content-end gap-3">
                                    <Link to={'/projects/' + item.id} className="text-dark" onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        navigate('/projects/' + item.id);
                                    }}>
                                        <IconPencil size={16} stroke={2} />
                                    </Link>
                                    <Link to="#" className="text-danger" onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        onDelete(item.id)
                                    }}>
                                        <IconTrash size={16} stroke={2} />
                                    </Link>
                                </div>
                            </h6>
                            <span className={'text-muted'}>{item.id}</span>
                        </div>
                    </div>

                    <button className="btn btn-outline-success text-nowrap shown-on-hover">
                        Обрати проєкт
                        <IconArrowNarrowRight size={20} stroke={2} className="ms-3" />
                    </button>
                </div>
            )
        })}
    </>);
}