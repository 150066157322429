import React from "react";

export default function NL2BR({content = '', prefix = null}) {
    return content ? (
        content.
        split('\n').
        //filter(paragraph => paragraph.trim() !== '').
        map((paragraph, key, paragraphs) => {
            return <React.Fragment key={key}>
                {(prefix && !key) ? prefix : ''}
                {paragraph}
                {(key !== (paragraphs.length - 1)) ? <br/> : ''}
            </React.Fragment>
        })
    ) : prefix;
}