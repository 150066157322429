import React, {useEffect} from "react";
import Grades from "../../../helpers/Grades";
import {IconExclamationCircle, IconSquareRoundedCheckFilled} from "@tabler/icons-react";

export default function PublicationCardProgress({publication, className = 'mt-3'}) {
    const [progressClassName, setProgressClassName] = React.useState('light');

    const [errors, setErrors] = React.useState([]);
    const [progress, setProgress] = React.useState(0);

    const blankChecklist = {
        media: false,
        caption: false,
        scheduled_for: false
    };
    const [checklist, setChecklist] = React.useState(blankChecklist);

    const setClassNameByGrade = () => {
        const curClassName = Grades.getClassNameByGrade(progress);

        setProgressClassName(curClassName);
    }


    useEffect(() => {
        setClassNameByGrade();
    }, [progress]);


    useEffect(() => {
        if (!publication || !publication.id) {setProgress(0); return;}

        let newProgress = 5,
            newChecklist = {...blankChecklist};

        if (publication.media.length) {
            newProgress += 45;
            newChecklist.media = true;
        }

        if (publication.platform !== 'stories' && publication.latestVersion && publication.latestVersion.caption) {
            newProgress += 25;
            newChecklist.caption = true;
        } else if (publication.platform === 'stories') {
            newProgress *= 1.5;
            newChecklist.caption = true;
        }

        if (publication.scheduled_for) {
            newProgress += 5;
            newChecklist.scheduled_for = true;
        }

        if (errors.length) {
            newProgress = Math.round(newProgress / 2);
        } else {
            newProgress += 20;
        }

        setChecklist(newChecklist);
        setProgress(newProgress);
    }, [publication, errors]);


    useEffect(() => {
        validatePublication();
    }, [publication]);


    const validatePublication = () => {
        if (!publication || !publication.id) {return;}

        let newErrors = [];

        // check if scheduled_for is in the future
        const now = new Date();
        const scheduledFor = new Date(publication.scheduled_for);
        if (scheduledFor < now) {
            newErrors.push({
                'field': 'scheduled_for',
                'message': 'Дата публікації повинна бути в майбутньому.'
            });
        }

        // check if media have same aspect ratio
        const media = publication.media;
        if (media.length > 1) {
            const aspectRatio = media[0].parameters && media[0].parameters.ar ? media[0].parameters.ar : '-';
            for (let i = 1; i < media.length; i++) {
                const newAspectRatio = media[i].parameters && media[i].parameters.ar ? media[i].parameters.ar : '-';
                if (newAspectRatio !== aspectRatio) {
                    newErrors.push({
                        'field': 'media',
                        'message': 'Медіа #'+(i+1)+' не відповідає пропорції першого медіа.'
                    });
                }
            }
        }

        setErrors(newErrors);
    }


    const getLabelByProgress = () => {
        if (errors.length) {
            return 'Необхідно усунути недоліки, '+errors.length+' шт.';
        }

        if (!checklist.media && !checklist.caption && !checklist.scheduled_for) {
            return 'Наповнення очікується.';
        }

        let missingParts = [];
        if (!checklist.media) {
            missingParts.push('медіа');
        }
        if (!checklist.caption) {missingParts.push('підпис');}
        if (!checklist.scheduled_for) {missingParts.push('дату публікації');}

        if (missingParts.length) {
            return 'Необхідно додати '+missingParts.join(' та ') + '.';
        }

        return 'Готово до публікації.';
    }


    return (
        <div className={'publication-card-progress '+className}>
            <div className="fs-5 fw-semibold text-start pb-0 d-flex justify-content-between">
                {errors.length ? (
                    <h5 className="text-danger">
                        <IconExclamationCircle size={20} stroke={2} className="me-2"/>
                        Потрібна увага
                    </h5>
                ) : (progress === 100 ? (
                    <h5 className="text-success">
                        <IconSquareRoundedCheckFilled size={20} stroke={2} className="me-2"/>
                        Підготовку завершено!
                    </h5>
                ) : (
                    <h5>Готовність</h5>
                ))}
                <span>{Math.round(progress)}%</span>
            </div>
            <h6 className="card-subtitle mb-2 fs-2 text-muted text-muted text-start">{getLabelByProgress()}</h6>

            <div className="progress bg-light" style={{height: '4px'}}>
                <div className={'progress-bar bg-'+progressClassName} role="progressbar" style={{width: progress+'%', height: '4px'}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
            </div>

            {errors.length ? (
                <div className="alert alert-danger mt-3 fs-2 text-danger">
                    <ul className="m-0">
                        {errors.map((error, index) => (
                            <li key={index}>{error.message}</li>
                        ))}
                    </ul>
                </div>
            ) : ''}
        </div>
    );
}