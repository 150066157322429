import React, {useEffect} from 'react';
import SectionNav from "../../layout/SectionNav";
import {
    IconArrowNarrowLeft,
    IconArrowNarrowRight,
    IconExclamationCircle,
    IconInfoSquareRounded
} from "@tabler/icons-react";
import LineChartPlan from "../../ui/charts/LineChartPlan";
import ProgressBarStepped from "../../ui/ProgressBarStepped/ProgressBarStepped";
import PublicationsListTabled from "../../publications/common/PublicationsListTabled/PublicationsListTabled";
import {useApi} from "../../../providers/ApiProvider";
import {useAuth} from "../../../providers/AuthProvider";
import {useMeta} from "../../../providers/MetaProvider";
import {useParams} from "react-router-dom";
import PublicationPlansModel from "../../../models/Publications/PublicationPlansModel";
import Grades from "../../../helpers/Grades";


export default function PublicationPlanView({}) {
    const {planId} = useParams();
    const apiContext = useApi();
    const authContext = useAuth();
    const metaContext = useMeta();
    const projectEssentials = authContext.project;
    const publicationPlansModel = new PublicationPlansModel(apiContext.api, projectEssentials.id);

    const [plan, setPlan] = React.useState({});
    const [loading, setLoading] = React.useState('general');
    const [mobileActiveChartIndex, setMobileActiveChartIndex] = React.useState(0);
    const totalMobileCharts = 2;

    useEffect(() => {
        getPlanData();
    }, []);


    const getPlanData = () => {
        setLoading('general');

        publicationPlansModel.view(planId).then((response) => {
            if (response !== false) {
                setPlan(response);

                updateMetaData(response);
            }
        }).finally(() => {
            setLoading('');
        });
    }


    const updateMetaData = (data) => {
        const newTitle = 'План від ' + Grades.getDateSinceFormatted(data.date_since) + ' для ' + data.project.title + ' | Likengale';

        metaContext.update({
            title: newTitle
        });
    }


    const onClickForward = (e) => {
        e.preventDefault();
        let nextIndex = mobileActiveChartIndex + 1;
        if (nextIndex >= totalMobileCharts) {
            nextIndex = 0;
        }

        setMobileActiveChartIndex(nextIndex);
    }
    const onClickBackward = (e) => {
        e.preventDefault();
        let nextIndex = mobileActiveChartIndex - 1;
        if (nextIndex < 0) {
            nextIndex = totalMobileCharts - 1;
        }

        setMobileActiveChartIndex(nextIndex);
    }


    const visibilityDummyData = {
        current: [{x: 0, y: 0}, {x: 5, y: 0.3}, {x: 10, y: 0.7}, {x: 15, y: 1.35}, {x: 20, y: 1.9}, {x: 25, y: 2.7}, {x: 30, y: 2.9}, {x: 35, y: 4.1}, {
            x: 40,
            y: 4.9
        }, {x: 45, y: 6.4}, {x: 50, y: 7.5}, {x: 55, y: 9.1}, {x: 60, y: 9.5}],
        average: [{x: 0, y: 0}, {x: 5, y: 0.7}, {x: 10, y: 1.6}, {x: 15, y: 3.1}, {x: 20, y: 4.2}, {x: 25, y: 4.9}, {x: 30, y: 5.8}, {x: 35, y: 6}, {
            x: 40,
            y: 8.5
        }, {x: 45, y: 8.9}, {x: 50, y: 9.9}, {x: 55, y: 10.5}, {x: 60, y: 11.2}],
        best: [{x: 0, y: 0}, {x: 5, y: 1}, {x: 10, y: 2}, {x: 15, y: 4}, {x: 20, y: 5}, {x: 25, y: 7}, {x: 30, y: 9}, {x: 35, y: 10}, {x: 40, y: 11}, {
            x: 45,
            y: 11.5
        }, {x: 50, y: 12.9}, {x: 55, y: 13.5}, {x: 60, y: 17.9}],
        expected: [{x: 0, y: 0}, {x: 60, y: 8}],
    };

    const efficiencyDummyData = {
        current: [{x: 0, y: 0}, {x: 5, y: 0.3}, {x: 10, y: 0.7}, {x: 15, y: 1.35}, {x: 20, y: 1.9}, {x: 25, y: 2.7}, {x: 30, y: 2.9}, {x: 35, y: 4.1}, {
            x: 40,
            y: 4.9
        }, {x: 45, y: 6.4}, {x: 50, y: 7.5}, {x: 55, y: 9.1}, {x: 60, y: 9.5}],
        average: [{x: 0, y: 0}, {x: 5, y: 0.7}, {x: 10, y: 1.6}, {x: 15, y: 3.1}, {x: 20, y: 4.2}, {x: 25, y: 4.9}, {x: 30, y: 5.8}, {x: 35, y: 6}, {
            x: 40,
            y: 8.5
        }, {x: 45, y: 8.9}, {x: 50, y: 9.9}, {x: 55, y: 10.5}, {x: 60, y: 11.2}],
        best: [{x: 0, y: 0}, {x: 5, y: 1}, {x: 10, y: 2}, {x: 15, y: 4}, {x: 20, y: 5.2}, {x: 25, y: 7}, {x: 30, y: 9}, {x: 35, y: 10}, {x: 40, y: 11}, {
            x: 45,
            y: 11.5
        }, {x: 50, y: 12.9}, {x: 55, y: 13.5}, {x: 60, y: 17.9}],
        expected: [{x: 0, y: 0}, {x: 60, y: 8}],
    }


    return plan ? (
        <>
            <SectionNav plan={plan} />

            <div className="d-flex flex-column block-gap">
                <div className="row block-gutter">
                    <div className="col-xl-5">
                        <div className="d-flex flex-column block-gap-single">
                            <div className="simple-block">
                                <h5>Чек-ліст</h5>

                                <ProgressBarStepped
                                    total={10}
                                    progress={4}
                                    label="42%"
                                />

                                <div className="text-end">
                                    <button className="btn btn-sm btn-link btn-link-warning d-inline-flex text-decoration-dashed p-0 mt-2" id="open-checklist-label"
                                            data-bs-toggle="dropdown" aria-expanded="false">Відкрити чек-ліст
                                    </button>

                                    <div className="dropdown-menu" aria-labelledby="open-checklist-label">
                                        <div className="d-flex align-items-center justify-content-between py-3 px-7">
                                            <h5 className="mb-0 fs-5 fw-semibold">Чек-ліст</h5>
                                            <span className="badge bg-primary rounded-4 px-3 py-1 lh-sm">5 new</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {plan.task_description ? (
                            <div className="simple-block">
                                <h5>Тема та нотатки</h5>
                                <div>{plan.task_description}</div>
                            </div>
                            ) : ''}

                            <div className="simple-block alert alert-danger">
                                <h5 className="text-danger">
                                    <IconExclamationCircle size={20} stroke={2} className="me-2"/>
                                    Зауваження
                                </h5>
                                <div>Згідно з планом, недостатня кількість публікацій в Instagram.</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-7">
                        <div className="d-flex gap-4 mb-st">
                            <button className="btn btn-sm btn-light-primary text-primary">Instagram</button>
                            <button className="btn btn-sm text-primary">Facebook</button>
                            <button className="btn btn-sm text-primary">Stories</button>
                        </div>

                        <div className="row block-gutter-lg">
                            <div className="col-xl-6">
                                <div className={'line-chart-noted' + (mobileActiveChartIndex === 0 ? ' active' : '')}>
                                    <div className="line-chart-noted-title">
                                        <h3>
                                            <div className="title-text">
                                                Видимість у першу годину
                                                <a href="#"><IconInfoSquareRounded size={20} stroke={2}/></a>
                                            </div>
                                            <div className="title-tools">
                                                <button className="btn btn-sm p-0" onClick={onClickForward}><IconArrowNarrowLeft size={20} stroke={2}/></button>
                                                <button className="btn btn-sm p-0" onClick={onClickBackward}><IconArrowNarrowRight size={20} stroke={2}/></button>
                                            </div>
                                        </h3>

                                        <p className="chart-description">Відсоток охопленої аудиторії відносно кількості підписників.</p>
                                    </div>

                                    <LineChartPlan
                                        data={visibilityDummyData}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className={'line-chart-noted' + (mobileActiveChartIndex === 1 ? ' active' : '')}>
                                    <div className="line-chart-noted-title">
                                        <h3>
                                            <div className="title-text">
                                                Ефективність у першу годину
                                                <a href="#"><IconInfoSquareRounded size={20} stroke={2}/></a>
                                            </div>
                                            <div className="title-tools">
                                                <button className="btn btn-sm p-0" onClick={onClickForward}><IconArrowNarrowLeft size={20} stroke={2}/></button>
                                                <button className="btn btn-sm p-0" onClick={onClickBackward}><IconArrowNarrowRight size={20} stroke={2}/></button>
                                            </div>
                                        </h3>

                                        <p className="chart-description">Відсоток взаємодії з публікаціями відносно охопленої аудиторії.</p>
                                    </div>

                                    <LineChartPlan
                                        data={efficiencyDummyData}
                                    />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <PublicationsListTabled />
            </div>
        </>
    ) : '...';
}