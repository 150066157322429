import React, {useEffect} from "react";

import PublicationCardProgress from "../common/PublicationCardProgress";
import {Link, useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsModel from "../../../models/Projects/ProjectsModel";
import ContentPlansModel from "../../../models/Publications/PublicationPlansModel";
import PublicationsModel from "../../../models/Publications/PublicationsModel";
import PublicationVersionsModel from "../../../models/Publications/PublicationVersionsModel";

import './PublicationEditHeader.css';
import {IconArrowLeft, IconExclamationCircle, IconPencil, IconShare} from "@tabler/icons-react";
import imgPlatformInstagram from "../../../assets/img/upgraded/ig.svg";
import imgPlatformFacebook from "../../../assets/img/upgraded/fb.svg";
import imgPlatformStories from "../../../assets/img/upgraded/instagram-stories.svg";
import imgBlank from "../../../assets/img/blank.png";
import PublicationBadges from "../common/PublicationBadges";


export default function PublicationEditHeader({publication = {}}) {
    const {planId} = useParams();


    const getPlatformIcon = () => {
        switch (publication.platform) {
            case 'instagram':
                return imgPlatformInstagram;
            case 'facebook':
                return imgPlatformFacebook;
            case 'stories':
                return imgPlatformStories;
            default:
                return imgBlank;
        }
    }


    return (<div className="publication-edit-header">
        <div className="back-button-container">
            <Link to={'/' + ['p', planId].join('/')}
                  className="btn btn-sm btn-light-primary text-primary justify-content-center align-items-center px-0 w-auto">
                <IconArrowLeft size={20} stroke={2}/>
            </Link>
        </div>
        <div className="header-container">
            <div className="publication-info-container">
                <div className="publication-info-container-top">
                    <div className="publication-info-title">
                        <h5 className="publication-info-subheader">
                            <img src={getPlatformIcon()} alt={publication.platform+' icon'} className="img-icon"/>
                            Тема публікації
                        </h5>

                        <h3 className="publication-info-theme">
                            {publication && publication.theme ? (
                                <span>{publication.theme.title}</span>
                            ) : (
                                <span className="placeholder-glow" style={{minWidth: '200px'}}><span className="placeholder placeholder-lg col-12"></span></span>
                            )}

                            <div className="publication-info-theme-toolbar">
                                <Link to="#">
                                    <IconShare size={20} stroke={2}/>
                                </Link>

                                <Link to={'/'+['p',planId,publication.id,'edit'].join('/')}>
                                    <IconPencil size={20} stroke={2}/>
                                </Link>
                            </div>
                        </h3>

                        <div className="publication-info-date text-muted fs-3">на понеділок, 11 листопада 2024 року</div>
                    </div>

                    <PublicationBadges
                        className="publication-info-badges"
                        publication={publication}
                    />
                </div>

                {publication.notes ? (
                    <div className="publication-info-notes">
                        <h5>Нотатки до публікації</h5>

                        <div className="">{publication.notes}</div>
                    </div>
                ) : ''}
            </div>
            <div className="warnings-container">
                <PublicationCardProgress publication={publication} className="w-100" />
            </div>
        </div>
    </div>);
}