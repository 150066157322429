import React from "react";

import './PublicationsPlanPreview.css';
import Grades from "../../../../helpers/Grades";
import {Link} from "react-router-dom";
import {IconTrash, IconX} from "@tabler/icons-react";


export default function PublicationsPlanRow({plan = {}}) {


    const getPlanType = () => {
        let dateSince = new Date(plan.date_since);
        dateSince.setHours(0, 0, 0, 0);

        let mondayThisWeek = new Date();
        mondayThisWeek.setHours(0, 0, 0, 0);
        mondayThisWeek.setDate(mondayThisWeek.getDate() - mondayThisWeek.getDay() + 1);

        if (dateSince.toDateString() === mondayThisWeek.toDateString()) {
            return 'current';
        } else if (dateSince > mondayThisWeek) {
            return 'future';
        } else {
            return 'past';
        }
    }


    return (
        <div className="content-row">
            <div className="content-row-title">
                <Link to={'/' + ['p', plan.id].join('/')} className="text-default fw-medium">{Grades.getDateSinceFormatted(plan.date_since)}</Link>
                {getPlanType() === 'current' ? (<div className="fs-2 text-muted">Поточний план</div>) : ''}
                {getPlanType() === 'future' ? (<div className="fs-2 text-success">Наступний план</div>) : ''}
            </div>
            <div className="content-row-actions">
                <Link to={'/' + ['p', plan.id].join('/')} className="btn btn-sm btn-light-primary text-primary">Перейти</Link>

                <button className="btn btn-icon text-danger"><IconTrash size={20} stroke={2} /></button>
            </div>
        </div>
    );
}