import React, {useState} from 'react';

import './ProjectTitleExtended.css';

import {IconBellRinging, IconChartLine, IconPencil, IconShare} from "@tabler/icons-react";
import {useAuth} from "../../../../providers/AuthProvider";
import imgProfileTmp from "modernize-essentials/dist/images/profile/user-1.jpg";
import {Link} from "react-router-dom";
import SimpleBar from "simplebar-react";

export default function ProjectTitleExtended({loading = true, project = {}}) {
    const [hasNotifications, setHasNotifications] = useState(true);


    return loading ? ('') : (
        <div className="project-title-extended">
            <div className={'project-title-image' + (hasNotifications ? ' active' : '')}>
                <img src={project.logo ? project.logo : imgProfileTmp} alt="Profile image"/>
            </div>

            <div className="project-title-info">
                <div className="project-title-name">
                    <div className="project-title-heading">
                        <h6>{project.title}</h6>

                        <div className="project-title-actions d-none d-lg-flex">
                            <IconBellRinging size={20} stroke={2}/>

                            <IconChartLine size={20} stroke={2}/>

                            <IconShare size={20} stroke={2}/>

                            <Link to={'/projects/'+project.id}>
                                <IconPencil size={20} stroke={2}/>
                            </Link>
                        </div>
                    </div>

                    <span>@{project.id}</span>
                </div>

                <div className="w-100">
                    <SimpleBar autoHide={true} style={{overflowY: 'hidden'}}>
                        <div className="project-title-links">
                            {Object.keys(project.accounts).map((key, index) => {
                                if (key === 'instagram') {
                                    return (<Link key={index} to={'https://instagram.com/'+project.accounts.instagram.username} target="_blank">Instagram</Link>);
                                }
                                if (key === 'facebook') {
                                    return (<Link key={index} to={'https://facebook.com/'+project.accounts.facebook.username} target="_blank">Facebook</Link>);
                                }
                            })}
                            {project.parameters && project.parameters.links ? Object.keys(project.parameters.links).map((key, index) => {
                                return (<Link key={index} to={project.parameters.links[key].link} target="_blank">{project.parameters.links[key].title}</Link>);
                            }) : ''}
                        </div>
                    </SimpleBar>
                </div>
            </div>
        </div>
    );
}
