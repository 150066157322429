import React from "react";
import { IconSearch, IconX } from '@tabler/icons-react';



export default function HeaderSearch({}) {
    const [searchTextState, setSearchTextState] = React.useState('');
    const [searchActive, setSearchActive] = React.useState(false);
    const [isForced, setIsForced] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const mouseoverTimeout = React.useRef(null);

    const activate = () => setSearchActive(true);
    const activateDelayed = () => {
        mouseoverTimeout.current = setTimeout(activate, 300);
    }
    const activateForced = () => setIsForced(true);
    const deactivate = () => {
        setSearchActive(false);
        clearTimeout(mouseoverTimeout.current);
    }
    const deactivateForced = () => {
        setIsForced(false);
        deactivate();
    }


    const onSearchSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        setTimeout(() => {
            window.location.href = '/search/'+searchTextState;
        }, 500);
    }


    return (
        <>
            <form
                action={'#'}
                className={'header-search d-none d-lg-flex' + (searchActive || isForced ? ' active' : '')}

                onFocus={activateForced}
                onMouseOver={activateDelayed}

                onBlur={deactivateForced}
                onMouseOut={deactivate}

                onSubmit={onSearchSubmit}
            >
                <input type="text" className="input-search" value={searchTextState} placeholder="Пошук..." onChange={(e) => setSearchTextState(e.target.value)} />

                <button type="button" className={'btn btn-search'} onClick={onSearchSubmit}>
                    {!loading ? (
                    <IconSearch size={16} stroke={3} />
                    ) : (
                    <span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden={false}></span>
                    )}
                </button>
            </form>

            <div className="d-flex d-lg-none justify-content-end pe-st w-100">
                <button
                    className={'btn btn-circle d-flex align-items-center justify-content-center ' + (searchActive ? 'btn-light-danger text-danger' : 'text-muted')}
                    onClick={(e) => setSearchActive(!searchActive)}
                >
                    {searchActive ? (<IconX size={16} stroke={3} />) : (<IconSearch size={16} stroke={3} />)}
                </button>

                <form
                    action={'#'}
                    className={'header-search-mobile' + (!searchActive ? ' d-none' : '')}

                    onSubmit={onSearchSubmit}
                >
                    <input type="text" className="input-search" value={searchTextState} placeholder="Пошук..."
                           onChange={(e) => setSearchTextState(e.target.value)}/>

                    <button type="button" className={'btn btn-search'} onClick={onSearchSubmit}>
                        {!loading ? (
                            <IconSearch size={16} stroke={3}/>
                        ) : (
                            <span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden={false}></span>
                        )}
                    </button>
                </form>
            </div>
        </>
    );
}