import React from "react";
import CodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import { basicDark } from '@uiw/codemirror-theme-basic';

export default function FormTextArea({label, name, errors, onChange, value, placeholder = '', groupClassName = '', className = '', required = false, disabled = false, readOnly = false, autoComplete = 'off', autoFocus = false, hint = '', rows = 3, isCodeMirror = false}) {

    const onCodeMirrorChange = (value, viewUpdate) => {
        onChange(value);
    };


    const onTextareaChange = (e) => {
        onChange(e.target.value);
    }

    return (
        <div className={'form-group '+groupClassName}>
            {label && (
            <label className="form-label fw-semibold mb-2" htmlFor={'cf-'+name}>{label}</label>
            )}

            {isCodeMirror ?
                <CodeMirror value={value ? value : ''} extensions={[json()]} onChange={onCodeMirrorChange} theme={basicDark} minHeight={'200px'} /> :
                <textarea
                    id={'cf-' + name}
                    className={'form-control ' + className + (errors && errors[name] ? ' is-invalid' : '')}
                    value={value ? value.toString() : ''}
                    onChange={onTextareaChange}
                    placeholder={placeholder}
                    required={required}
                    disabled={disabled}
                    readOnly={readOnly}
                    autoComplete={autoComplete}
                    autoFocus={autoFocus}
                    rows={rows}
                />
            }


            <div className="d-flex justify-content-between mt-1">
                {errors && errors[name] && <div className="form-text text-danger">{errors[name].join(' ')}</div>}
                <div className={'flex-grow-1 text-end fs-2'}>{hint}</div>
            </div>
        </div>
    )
}