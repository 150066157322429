import React, { useEffect } from 'react';
import Chart from "react-apexcharts";
import { ChartStyles } from "./ChartStyles";


export default function LineChartPlan({ data, width = '100%', height = '320px', className = '', shouldBeGraded = false }) {
    const chartStyles = {
        ...ChartStyles,
        chart: {
            ...ChartStyles.chart,
            id: "pq-insights-chart-" + Math.round((Math.random() * 1000000)),
            type: "area",
        },
        fill: {
            ...ChartStyles.fill,
            type: ['gradient', 'solid', 'solid', 'solid'],
        },
        stroke: {
            curve: ['monotoneCubic', 'monotoneCubic', 'monotoneCubic', 'straight'],
            width: [2, 1, 1, 1],
        },
        xaxis: {
            ...ChartStyles.xaxis,
            type: 'numeric',
            tickAmount: 3,
            decimalsInFloat: 0,
        },
        yaxis: {
            ...ChartStyles.yaxis,
            tickAmount: 4,
            decimalsInFloat: 0,
        },
    };
    const [chartOptions, setChartOptions] = React.useState(chartStyles);
    const [dataSeries, setDataSeries] = React.useState({
        current: {
            name: 'Поточна',
            color: "#3771C8",
            type: 'area',
            zIndex: -40,
            data: []
        },
        average: {
            name: 'Середня',
            color: "#6D2AF6",
            type: 'line',
            zIndex: 30,
            data: []
        },
        best: {
            name: 'Найкраща',
            color: "#FB417F",
            type: 'line',
            zIndex: 20,
            data: []
        },
        expected: {
            name: 'Очікувана',
            color: "#0FBA00",
            type: 'line',
            zIndex: 10,
            data: []
        }
    });
    const [dataSeriesAsArray, setDataSeriesAsArray] = React.useState([]);


    useEffect(() => {
        if (!data) return;

        let dataSeriesNew = { ...dataSeries };

        Object.keys(data).forEach((key, index) => {
            dataSeriesNew[key].data = data[key];
        });

        /*if (shouldBeGraded && data.series.length) {
            areaOptionsNew.colors = gradeColorsBySeries(data.series);
        }*/

        setDataSeries(dataSeriesNew);
        setDataSeriesAsArray(Object.values(dataSeriesNew));
    }, [data]);


    return dataSeriesAsArray.length && (
        <Chart type="area" options={chartOptions} series={dataSeriesAsArray} width={width} height={height} className={className} />
    );
}