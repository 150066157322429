import React, {useEffect, useState} from 'react';

import ReactCrop, {centerCrop, makeAspectCrop} from 'react-image-crop'
import {useApi} from "../../../providers/ApiProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import PublicationMediaModel from "../../../models/Publications/PublicationMediaModel";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import SimpleModal from "../../ui/SimpleModal";

import 'react-image-crop/dist/ReactCrop.css';
import {IconBoxModel, IconCropLandscape, IconCropPortrait, IconShape} from "@tabler/icons-react";
import PublicationMediaCropInstagramSquare from "./PublicationMediaCropInstagramSquare";
import PublicationMediaCropSafeZones from "./PublicationMediaCropSafeZones";

export default function PublicationMediaCrop({publication, media, onMediaChanged, onModalClose}) {
    const apiContext = useApi();
    const notificationsContext = useNotifications();
    const publicationMediaModel = new PublicationMediaModel(apiContext.api, publication.id);

    const [crop, setCrop] = useState({
        unit: '%',
        x: 25,
        y: 25,
        width: 50,
        height: 50
    });
    const [completeCrop, setCompleteCrop] = useState(null);
    const [loading, setLoading] = React.useState(false);
    const [aspectRatio, setAspectRatio] = React.useState(null);
    const [addonEnabled, setAddonEnabled] = React.useState(null);

    useEffect(() => {
        let el = document.querySelector('.modal-media-crop img');
        if (!el) {return;}

        let width = el.naturalWidth,
            height = el.naturalHeight;

        let newCrop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 100,
                },
                aspectRatio,
                width,
                height
            ),
            width,
            height
        );

        setCrop(newCrop);
    }, [aspectRatio]);


    const aspectRatioToNumber = (ratio) => {
        let value = null;
        if (ratio.indexOf(':') !== -1) {
            let parts = ratio.split(':');
            value = parseInt(parts[0]) / parseInt(parts[1]);
        }

        return value;
    }


    const updateAspectRatio = (ratio) => {
        const newRatio = aspectRatioToNumber(ratio);
        setAspectRatio(newRatio);
    }


    const onComplete = (crop, percentCrop) => {
        setCompleteCrop(percentCrop);
    }


    const onSave = () => {
        if (!media) {return;}

        let workingCrop = completeCrop;
        if (!workingCrop) {
            workingCrop = crop;
        }

        setLoading(true);

        let cropData = {
            x: workingCrop.x,
            y: workingCrop.y,
            width: workingCrop.width,
            height: workingCrop.height,
        };

        publicationMediaModel.crop(media.id, cropData).then((response) => {
            if (response !== false) {
                onMediaChanged();
                onModalClose();
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const prepareModalClose = () => {
        setCrop({
            unit: '%',
            x: 25,
            y: 25,
            width: 50,
            height: 50
        });
        setCompleteCrop(null);
        setAspectRatio(null);

        onModalClose();
    }


    const renderSelectionAddon = (state) => {
        switch (addonEnabled) {
            case 'instagram-square':
                return (<PublicationMediaCropInstagramSquare state={state} crop={crop} aspectRatio={aspectRatio} />);
            case 'safe-zones':
                return (<PublicationMediaCropSafeZones state={state} crop={crop} />);
            default:
                return '';
        }
    }


    return !!media ? (<SimpleModal
        visible={!!media}
        loading={false}
        onClose={onModalClose}
        modalContentClassName={'modal-md'}
        headerTitle={'Редагування зображення'}
    >
        <ReactCrop
            className="modal-media-crop"
            crop={crop}
            aspect={aspectRatio}
            onChange={c => setCrop(c)}
            onComplete={onComplete}
            ruleOfThirds={true}
            renderSelectionAddon={renderSelectionAddon}
            style={{maxHeight: '60vh'}}
        >
            <img src={media.filename} alt=""/>
        </ReactCrop>

        <div className="my-2 fs-3 fw-semibold">Пропорція зображення</div>

        <div className="d-flex gap-3 mb-2">
            <div className="border-end pe-3">
                <IconCropLandscape size={24} stroke={2}/>
            </div>
            <div className="d-flex justify-content-start align-items-center gap-2">
                {['5:4', '3:2', '2:1', '16:9'].map((ratio, index) => (
                    <button key={index} type="button"
                            className={'btn btn-sm px-2 ' + ((aspectRatioToNumber(ratio) === aspectRatio) ? 'btn-primary text-white' : 'btn-light-primary text-primary')}
                            onClick={() => {
                                updateAspectRatio(ratio)
                            }}>
                        {ratio}
                    </button>
                ))}
            </div>
        </div>

        <div className="d-flex gap-3 mb-2">
            <div className="border-end pe-3">
                <IconCropPortrait size={24} stroke={2}/>
            </div>
            <div className="d-flex justify-content-start align-items-center gap-2">
                {['4:5', '2:3', '1:2', '9:16'].map((ratio, index) => (
                    <button key={index} type="button"
                            className={'btn btn-sm px-2 ' + ((aspectRatioToNumber(ratio) === aspectRatio) ? 'btn-primary text-white' : 'btn-light-primary text-primary')}
                            onClick={() => {
                                updateAspectRatio(ratio)
                            }}>
                        {ratio}
                    </button>
                ))}
            </div>
        </div>

        <div className="d-flex gap-3 mb-2">
            <div className="border-end pe-3">
                <IconShape size={24} stroke={2}/>
            </div>
            <div className="d-flex justify-content-start align-items-center gap-2">
                {['1:1', 'Вільно'].map((ratio, index) => (
                    <button key={index} type="button"
                            className={'btn btn-sm px-2 ' + ((aspectRatioToNumber(ratio) === aspectRatio) ? 'btn-primary text-white' : 'btn-light-primary text-primary')}
                            onClick={() => {
                                updateAspectRatio(ratio)
                            }}>
                        {ratio}
                    </button>
                ))}
            </div>
        </div>

        {['instagram', 'stories'].includes(publication.platform) ? (
        <div className="d-flex gap-3 mb-2 mt-3 pt-3 border-1 border-top">
            <div className="border-end pe-3">
                <IconBoxModel size={24} stroke={2}/>
            </div>
            <div className="d-flex justify-content-start align-items-center gap-2">
                {publication.platform === 'instagram' ? (
                <button type="button"
                        className={'btn btn-sm px-2 ' + ((addonEnabled === 'instagram-square') ? 'btn-warning text-white' : 'btn-light-warning text-warning')}
                        onClick={() => {
                            if (addonEnabled === 'instagram-square') {
                                setAddonEnabled(null);
                            } else {
                                setAddonEnabled('instagram-square');
                            }
                        }}>
                    Квадрат Instagram
                </button>
                ) : ''}
                {publication.platform === 'stories' ? (
                <button type="button"
                        className={'btn btn-sm px-2 ' + ((addonEnabled === 'safe-zones') ? 'btn-warning text-white' : 'btn-light-warning text-warning')}
                        onClick={() => {
                            if (addonEnabled === 'safe-zones') {
                                setAddonEnabled(null);
                            } else {
                                setAddonEnabled('safe-zones');
                            }
                        }}>
                    Зони безпеки
                </button>
                ) : ''}
                <button type="button"
                        className={'btn btn-sm px-2 btn-warning'}
                        onClick={() => setAddonEnabled(null)}>
                    Очистити
                </button>
            </div>
        </div>
        ) : ''}

        <div className="form-group mt-3 gap-3 d-flex justify-content-between">
            <button className="btn btn-light-danger text-danger" onClick={prepareModalClose}>Відмінити</button>

            <ButtonWithLoading
                className="btn btn-success"
                loading={loading}
                label={'Зберегти'}
                noOriginalIconClassName={true}
                iconClassName={'d-none'}
                onClick={onSave}
            />
        </div>
    </SimpleModal>) : '';
}