import React, { useEffect } from 'react';
import Chart from "react-apexcharts";
import { ChartStyles } from "./ChartStyles";


export default function LineAndPointChart({ data, level, width = '100%', height = '320px', className = '', shouldBeGraded = false }) {

    const chartStyles = {
        ...ChartStyles,
        chart: {
            ...ChartStyles.chart,
            id: "pq-insights-chart-" + Math.round((Math.random() * 1000000)),
            type: "line",
        },
        fill: {
            ...ChartStyles.fill,
            type: ['solid', 'solid'],
        },
        stroke: {
            curve: ['straight', 'monotoneCubic'],
            width: [2, 0],
        },
        markers: {
            size: [0, 5],
        },

        xaxis: {
            ...ChartStyles.xaxis,
            type: 'datetime',
            labels: {
                format: 'dd.MM', // Форматування дати
                rotate: -45, // Кут повороту міток
                rotateAlways: true // Завжди повертати мітки
            },
            tooltip: {
                format: 'dd.MM'
            }
        },
        yaxis: {
            ...ChartStyles.yaxis,
            decimalsInFloat: 0, // Кількість десяткових знаків в мітках
            forceNiceScale: true, // Примусово використовувати 'гарну шкалу' для міток
            logarithmic: false // Вибір логарифмічної шкали, якщо потрібно
        }
    };

    const [chartOptions, setChartOptions] = React.useState(chartStyles);
    const [dataSeries, setDataSeries] = React.useState({
        level: {
            name: 'За планом',
            color: "#FB417F",
            type: 'line',
            zIndex: 40,
            data: []
        },
        byDate: {
            name: 'Фактично',
            color: "#3771C8",
            type: 'line',
            zIndex: -30,
            data: []
        },
    });
    const [dataSeriesAsArray, setDataSeriesAsArray] = React.useState([]);


    useEffect(() => {
        if (!data) return;

        let minValue = Infinity;
        let maxValue = -Infinity;
        Object.values(data).forEach(series => {
            series.forEach(point => {
                if (point.x < minValue) minValue = point.x;
                if (point.x > maxValue) maxValue = point.x;
            });
        });

        const levelData = [{ x: minValue, y: level }, { x: maxValue, y: level }];
        const updatedDataSeries = {
            ...dataSeries,
            level: {
                ...dataSeries.level,
                data: levelData
            }
        };

        Object.keys(data).forEach(key => {
            updatedDataSeries[key] = {
                ...dataSeries[key],
                data: data[key]
            };
        });

        setDataSeries(updatedDataSeries);
        setDataSeriesAsArray(Object.values(updatedDataSeries));
    }, [data, level]);


    return dataSeriesAsArray.length && (
        <Chart type="area" options={chartOptions} series={dataSeriesAsArray} width={width} height={height} className={className} />
    );
}