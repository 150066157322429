import React from 'react';

import defaultLogo from '../../../assets/img/project-logo-default.png';
import {Link} from "react-router-dom";
import dayjs from "dayjs";

export default function ProjectTitle({loading, project}) {


    //convert GMT to local time as DD MMM + YYYY if year differs from today's
    const getDateSinceFormatted = () => {
        if (!project.synced_at) {
            return '';
        }

        const date = dayjs(project.synced_at).locale('uk');
        const today = dayjs().locale('uk');
        let dateOptions = {day: 'numeric', month: 'long', year: 'numeric'};

        if (date.year() === today.year()) {
            dateOptions = {day: 'numeric', month: 'long'};
        }

        return date.format(dateOptions);
    }


    return project && (
        <div className="px-3 m-block-gap">
            <div className="d-flex align-items-center pb-2">
                <div className="me-2 pe-1">
                    <img src={project && project.logo ? project.logo : defaultLogo} className="shadow-warning rounded-circle" alt="" width="72" height="72"/>
                </div>
                <div className="w-100 d-flex align-items-start">
                    <div className="">
                        <h5 className="fw-semibold fs-5 mb-1 position-relative">
                            {project.title}
                        </h5>
                        <p className="fs-3 mb-0">від {getDateSinceFormatted()}</p>
                    </div>
                    <div className="d-flex gap-2 ms-3">
                        <Link to={'/'+['projects', project.id].join('/')} className="bg-light rounded py-1 px-8 d-inline-flex align-items-center text-decoration-none"
                           title="Редагувати">
                            <i className="ti ti-pencil fs-3 text-dark"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
