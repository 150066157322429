import BaseCrudModel from "../BaseCrudModel";

/**
 * Project Tasks model
 *
 * @param {ApiHelper} api
 */
export default class ProjectTasksModel extends BaseCrudModel {
    baseUrl = '/projects/{%PROJECT_ID%}/tasks';

    constructor(api, projectId) {
        super(api);
        this.baseUrl = this.baseUrl.replace('{%PROJECT_ID%}', projectId);
    }
}
