import React, {useEffect} from 'react';

import SimpleCardContainer from "../../ui/SimpleCardContainer";
import {useApi} from "../../../providers/ApiProvider";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import {Link, Navigate, useParams} from "react-router-dom";
import {useNotifications} from "../../../providers/NotificationsProvider";
import BrandsModel from "../../../models/Projects/ProjectTasksModel";
import ProjectTasksForm from "./ProjectTasksForm";
import {useAuth} from "../../../providers/AuthProvider";
import {useMeta} from "../../../providers/MetaProvider";
import ProjectTasksModel from "../../../models/Projects/ProjectTasksModel";

export default function ProjectTasksCreate({}) {
    const apiContext = useApi();
    const authContext = useAuth();
    const projectEssentials = authContext.project;
    const notificationsContext = useNotifications();
    const metaContext = useMeta();
    const projectTasksModel = new ProjectTasksModel(apiContext.api, projectEssentials.id);

    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const [formData, setFormData] = React.useState({});
    const [redirect, setRedirect] = React.useState('');


    useEffect(() => {
        metaContext.update({
            title: 'Додати нове завдання',
        });
    }, []);


    const onSubmit = (data) => {
        setLoading(true);

        projectTasksModel.create(data, onCreateError).then((response) => {
            if (response !== false) {
                setLoading(false);
                setErrors({});
                setFormData(response);

                notificationsContext.notify('Завдання успішно створено', 'success');

                setRedirect('/p/tasks/'+response.id);
            }
        }).catch((response) => {
            onCreateError(response);
        }).finally(() => {
            setLoading(false);
        });
    }


    const onCreateError = (response) => {
        if (response && response.data) {
            setErrors(response.data);
        }

        notificationsContext.notify('Не вдалося створити завдання', 'error');
    }


    return (<>
        {redirect && <Navigate to={redirect}/>}
        <div className="d-flex m-block-gap">
            <Link to={'/p/tasks'} className="btn btn-light-info text-info">
                <i className="ti ti-arrow-left me-2"></i>
                {'Назад до всіх завдань'}
            </Link>
        </div>
        <div style={{maxWidth: '800px'}}>
            <SimpleCardContainer title={'Додати нове завдання'} loading={loading}>
                <ProjectTasksForm
                    formData={formData}
                    className={'py-2'}
                    errors={errors}
                    isNewRecord={true}
                    onSubmit={onSubmit}
                />
            </SimpleCardContainer>
        </div>
    </>);
}
