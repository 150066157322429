import BaseCrudModel from "../BaseCrudModel";

/**
 * Project Themes model
 *
 * @param {ApiHelper} api
 */
export default class ProjectThemesModel extends BaseCrudModel {
    baseUrl = '/projects/{%PROJECT_ID%}/themes';

    constructor(api, projectId) {
        super(api);
        this.baseUrl = this.baseUrl.replace('{%PROJECT_ID%}', projectId);
    }
}
