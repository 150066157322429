import React from "react";

export default function PublicationCaptionPreview({caption = '', prefix = null, platform = 'instagram', onExpand}) {

    const INSTAGRAM_FEED_PREVIEW = 50;
    const INSTAGRAM_MAX_PREVIEW = 125;

    const FACEBOOK_MAX_PREVIEW = 125;


    const getCaption = () => {
        if (!caption) {return '';}

        const captionParagraphs = caption.split('\n');
        const firstParagraph = captionParagraphs[0];

        let showMore = false;
        if (
            captionParagraphs.length > 1 ||
            firstParagraph.length > (
                platform === 'instagram' ? INSTAGRAM_FEED_PREVIEW : FACEBOOK_MAX_PREVIEW
            )
        ) {
            showMore = true;
        }

        if (!showMore) {
            return caption;
        }

        return (<>
            {firstParagraph.substring(0, platform === 'instagram' ? INSTAGRAM_FEED_PREVIEW : FACEBOOK_MAX_PREVIEW) + '... '}
            <a href="#" onClick={onExpand}>більше</a>
        </>);
    }


    return (<>
        {prefix} {getCaption()}
    </>);
}