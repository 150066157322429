import React from "react";

import logoInstagram from './../../../assets/img/instagram.svg';
import logoFacebook from './../../../assets/img/facebook.svg';
import logoStories from './../../../assets/img/stories.svg';
import defaultCover from '../../../assets/img/post-4-5.png';
import defaultLogo from '../../../assets/img/project-logo-default.png';
import PublicationCardProgress from "./PublicationCardProgress";
import { Link, useParams } from "react-router-dom";
import PublicationPlanViewPlatformProgress from "../publicationPlans/PublicationPlanViewPlatformProgress";
import {IconArchive, IconArrowNarrowRight, IconArrowRight, IconPlus} from "@tabler/icons-react";
import PublicationPlanCreateModal from "../publicationPlans/PublicationPlanCreateModal";
import BadgePriority from "../../ui/badges/BadgePriority";
import BadgePublicationEntertainment from "../../ui/badges/BadgePublicationEntertainment";
import BadgePromoted from "../../ui/badges/BadgePromoted";
import BadgePublicationSales from "../../ui/badges/BadgePublicationSales";
import BadgePublicationEducational from "../../ui/badges/BadgePublicationEducational";


export default function PublicationBadges({publication = {}, className = ''}) {

    let badges = {
        priority: false,
        pubType: false,
        promoted: false,
    }

    if (publication && publication.parameters) {
        badges.priority = publication.parameters.priority;
        badges.promoted = publication.parameters.promoted;
        badges.pubType = publication.parameters.type;
    }

    const badgesVisible = badges.priority || badges.promoted || badges.pubType;


    const getTypeBadge = () => {
        if (!publication || !publication.parameters || !publication.parameters.type) {return null;}

        switch (publication.parameters.type) {
            case 'entertainment': return <BadgePublicationEntertainment/>;
            case 'sales': return <BadgePublicationSales/>;
            case 'educational': return <BadgePublicationEducational/>;
            default: return null;
        }
    }



	return badgesVisible ? (
        <div className={className}>
            {badges.priority ? (<BadgePriority/>) : ''}

            {getTypeBadge()}

            {badges.promoted ? (<BadgePromoted/>) : ''}
        </div>
    ) : '';
}
