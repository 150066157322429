import React from 'react';

export default function PublicationMediaCropSafeZones({state}) {
    return (
        <div className="d-flex justify-content-center align-items-center position-absolute w-100 h-100" style={{pointerEvents: 'none'}}>
            <div className="crop-safe-zones">
                <div className="safe-zone safe-zone-top"></div>
                <div className="safe-zone safe-zone-bottom"></div>

                <div className="content-area">
                </div>
            </div>
        </div>
    );
}