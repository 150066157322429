import React, {useEffect} from "react";

import './PublicationsListTabled.css';
import PublicationsListTabledItem from "./PublicationsListTabledItem";
import {Link, useParams} from "react-router-dom";
import {IconPlus} from "@tabler/icons-react";
import {useApi} from "../../../../providers/ApiProvider";
import {useAuth} from "../../../../providers/AuthProvider";
import PublicationPlansModel from "../../../../models/Publications/PublicationPlansModel";
import PublicationsModel from "../../../../models/Publications/PublicationsModel";
import SimpleModal from "../../../ui/SimpleModal";
import PublicationCreate from "../../edit/PublicationCreate";


export default function PublicationsListTabled({}) {
    const {planId} = useParams();
    const apiContext = useApi();
    const publicationsModel = new PublicationsModel(apiContext.api);

    const [loading, setLoading] = React.useState('general');
    const [publications, setPublications] = React.useState({});
    const [createPublicationModalVisible, setCreatePublicationModalVisible] = React.useState(false);


    const getPublications = () => {
        setLoading('general');

        publicationsModel.list({plan_id: planId}).then(response => {
            setPublications(response);
        }).finally(() => {
            setLoading(false);
        });
    }


    useEffect(() => {
        getPublications();
    }, []);


    const onDeletePublication = (pk) => {
        return new Promise((resolve, reject) => {
            if (!window.confirm('Ви впевнені, що хочете видалити цю публікацію?')) {
                reject();
            } else {
                resolve();
            }
        }).then(() => {
            return publicationsModel.delete(pk).then(() => {
                getPublications();
            })
        }).catch(() => {});
    }


    return (loading === 'general') ? '...' :(
        <div className="">
            <div className="d-flex gap-7 flex-row align-items-center">
                <h3 className="lh-1 m-0">Публікації</h3>

                <button className="btn btn-success btn-round text-nowrap" onClick={() => setCreatePublicationModalVisible(true)}>
                    <IconPlus size={20} stroke={2.5} className="me-2"/>
                    Додати публікацію
                </button>
            </div>

            {Object.keys(publications).length > 0 ? (
            <div className="publications-list-tabled">
                <div className="custom-responsive-behavior d-none d-lg-flex">
                    <div className="d-none d-lg-flex"></div>
                    <div>
                        <div className="publications-list-tabled-platforms">
                            <div><h5>&nbsp;</h5></div>
                            <div><h5>Час</h5></div>
                            <div><h5>Медіа</h5></div>
                            <div><h5>Текст</h5></div>
                            <div className="status-field"><h5>Статус</h5></div>
                            <div><h5>&nbsp;</h5></div>
                        </div>
                    </div>
                </div>

                {Object.keys(publications).map(pubDate => {
                    return Object.keys(publications[pubDate]).map(theme => {
                        return (
                            <PublicationsListTabledItem
                                key={pubDate+theme}
                                publications={publications[pubDate][theme]}
                                pubDate={pubDate}
                                theme={theme}
                                onDeletePublication={onDeletePublication}
                            />
                        );
                    });
                })}
            </div>
            ) : (
            <div className="text-center mt-5">
                <h5>Публікацій немає</h5>
            </div>
            )}

            <SimpleModal
                visible={createPublicationModalVisible}
                onClose={() => setCreatePublicationModalVisible(false)}
                headerTitle="Додати нову публікацію"
                modalContentClassName="modal-lg"
            >
                <PublicationCreate
                    onSuccess={() => {
                        setCreatePublicationModalVisible(false);
                        getPublications();
                    }}
                />
            </SimpleModal>
        </div>
    );
}