import React from "react";


export default function BadgePublicationEducational({children, className = '', ...props}) {

    return (
        <div className={'badge badge-publication-educational ' + className} {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                 strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-school">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6"/>
                <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4"/>
            </svg>
            Пізнавальна
        </div>
    );
}