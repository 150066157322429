import React, {useEffect} from "react";

import './PublicationsPlansList.css';
import {useApi} from "../../../../providers/ApiProvider";
import {useAuth} from "../../../../providers/AuthProvider";
import PublicationPlansModel from "../../../../models/Publications/PublicationPlansModel";
import SimpleCardContainer from "../../../ui/SimpleCardContainer";
import PublicationsPlanRow from "../preview/PublicationsPlanRow";


export default function PublicationsPlansList({}) {
    const apiContext = useApi();
    const authContext = useAuth();
    const projectEssentials = authContext.project;
    const publicationPlansModel = new PublicationPlansModel(apiContext.api, projectEssentials.id);

    const [loading, setLoading] = React.useState('general');
    const [plans, setPlans] = React.useState({});


    const getData = () => {
        setLoading('general');

        publicationPlansModel.list().then((response) => {
            if (response !== false) {
                setPlans(response);
            }
        }).finally(() => {
            setLoading('');
        });
    }


    useEffect(() => {
        getData();
    }, []);


    return (<>
        <SimpleCardContainer
            loading={loading}
            title="Плани публікацій"
        >

        {loading ? (<div>
            <div className="placeholder-glow">
                <span className="placeholder"></span>
            </div>
        </div>) : (<>
            {plans && plans.data && plans.data.length > 0 ? (
            <div className="mt-3">
                {plans.data.map((plan, index) => (
                    <PublicationsPlanRow key={index} plan={plan} />
                ))}
            </div>
            ) : (
                <div className="mt-3">
                    <p>Планів публікацій не знайдено</p>
                </div>
            )}
        </>)}

        </SimpleCardContainer>
    </>);
}