import React from "react";
import {IconStar} from "@tabler/icons-react";


export default function BadgePriority({children, className = '', ...props}) {

    return (
        <div className={'badge badge-priority ' + className} {...props}>
            <IconStar size={17} stroke={2} />
            Пріоритетна
        </div>
    );
}