import BaseCrudModel from "../BaseCrudModel";

/**
 * Publication Versions model
 *
 * @param {ApiHelper} api
 */
export default class PublicationVersionsModel extends BaseCrudModel {
    baseUrl = '/publications/{%PUBLICATION_ID%}/versions';

    constructor(api, publicationId) {
        super(api);
        this.baseUrl = this.baseUrl.replace('{%PUBLICATION_ID%}', publicationId);
    }
}
