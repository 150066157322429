import React, {useEffect} from 'react';

export default function PublicationMediaCropInstagramSquare({state, crop, aspectRatio}) {
    const [orientation, setOrientation] = React.useState('landscape');

    useEffect(() => {
        let newOrientation;

        if (aspectRatio !== null) {
            newOrientation = 'landscape';
            if (aspectRatio < 1) {
                newOrientation = 'portrait';
            }
        } else {
            if (crop.width >= crop.height) {
                newOrientation = 'landscape';
            } else {
                newOrientation = 'portrait';
            }
        }

        if (newOrientation !== orientation) {
            setOrientation(newOrientation);
        }
    }, [crop, aspectRatio]);


    return (
        <div className="d-flex justify-content-center align-items-center position-absolute w-100 h-100" style={{pointerEvents: 'none'}}>
            <div className={'crop-instagram-square' + (orientation === 'landscape' ? ' landscape-orientation' : '')}></div>
        </div>
    );
}