import React, {useEffect} from "react";

import './PublicationsPlanPreview.css';
import PublicationPlanCard from "../../common/PublicationPlanCard";
import {useApi} from "../../../../providers/ApiProvider";
import {useAuth} from "../../../../providers/AuthProvider";
import PublicationPlansModel from "../../../../models/Publications/PublicationPlansModel";
import dayjs from "dayjs";
import 'dayjs/locale/uk';


export default function PublicationsPlanPreview({}) {
    const apiContext = useApi();
    const authContext = useAuth();
    const projectEssentials = authContext.project;
    const publicationPlansModel = new PublicationPlansModel(apiContext.api, projectEssentials.id);

    const [loading, setLoading] = React.useState('general');
    const [plan, setPlan] = React.useState({});
    const [planType, setPlanType] = React.useState('');


    const getData = () => {
        setLoading('general');

        publicationPlansModel.actual().then((response) => {
            if (response !== false) {
                setPlan(response);
                setPlanType(getPlanType(response));
            }
        }).finally(() => {
            setLoading('');
        });
    }


    const getPlanType = (newPlan) => {
        if (!newPlan) {
            newPlan = plan;
        }

        let dateSince = dayjs(newPlan.date_since).locale('uk').format('YYYY-MM-DD');
        console.log(dateSince);

        let mondayThisWeek = dayjs().locale('uk').startOf('week').format('YYYY-MM-DD');
        console.log(mondayThisWeek);

        if (dateSince === mondayThisWeek) {
            return 'current';
        } else if (dateSince > mondayThisWeek) {
            return 'future';
        } else {
            return 'past';
        }
    }


    useEffect(() => {
        getData();
    }, []);


    return loading ? '...' : (
        <div className="publications-plan-preview">
            <div className="mb-double-st">
                <PublicationPlanCard
                    plan={plan}
                    planType={planType}
                    projectId={projectEssentials.id}
                />
            </div>
        </div>
    );
}