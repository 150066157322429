import React from 'react';
import {Link} from "react-router-dom";


const PublicationMediaPreview = React.forwardRef(({media = {filename: '', media_type: 'image'}, provided, style, isDragging, onSelectPreview, onCrop, onDelete}, ref) => {


    const onLocalSelectPreview = () => {onSelectPreview(media);}
    const onLocalCrop = () => {onCrop(media);}
    const onLocalDelete = () => {onDelete(media);}


    return (
    <div
        className="publication-media-preview"
        ref={ref}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{...style}}
    >
        <div className="main-media">
            <img src={media.media_type === 'video' && media.parameters && media.parameters.preview ? media.parameters.preview : media.filename} alt={''} />
        </div>
        {media.media_type === 'video' ? (
        <div className="bg-primary text-white video-icon"><i className="ti ti-player-play fs-2"></i></div>
        ) : ''}

        <div className="media-toolbar gap-2">
            <Link to={media.filename} target="_blank" className="btn btn-sm btn-light btn-icon d-flex justify-content-center align-items-center"><i className="ti ti-external-link fs-4"></i></Link>
            {media.media_type === 'video' ? (
            <button className="btn btn-sm btn-light btn-icon d-flex justify-content-center align-items-center" onClick={onLocalSelectPreview}><i className="ti ti-carousel-horizontal fs-4"></i></button>
            ) : (
            <button className="btn btn-sm btn-light btn-icon d-flex justify-content-center align-items-center" onClick={onLocalCrop}><i className="ti ti-crop fs-4"></i></button>
            )}
            <button className="btn btn-sm btn-danger btn-icon d-flex justify-content-center align-items-center" onClick={onLocalDelete}><i className="ti ti-trash fs-4"></i></button>
        </div>
    </div>);
});

export default PublicationMediaPreview;