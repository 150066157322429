export const ChartStyles = {
    chart: {
        fontFamily: '"Inter", Arial, sans-serif',
        foreColor: "#adb0bb",
        toolbar: {
            show: false,
        },
    },
    fill: {
        gradient: {
            shade: 'light',
            type: 'vertical',
            shadeIntensity: 0,
            opacityFrom: 0.4,
            opacityTo: 0,
            stops: [0, 100]
        }
    },
    markers: {
        show: false,
        size: 0,
    },
    grid: {
        show: false
    },
    dataLabels: {
        show: false,
        enabled: false,
    },
    legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'left',
        fontSize: '9px',
        fontWeight: 700,
        formatter: function (seriesName, opts) {
            return seriesName.toUpperCase()
        },
        offsetX: -2,
        offsetY: 10,
        height: 20,
        markers: {
            shape: 'circle',
            size: 4.5,
            strokeWidth: 0,
            offsetX: -3
        },
        itemMargin: {
            horizontal: 10, // Space between legend items
            vertical: 0
        },
        labels: {
            colors: '#333', // Color for legend text
            useSeriesColors: true
        }
    },
    xaxis: {
        position: 'bottom',
        labels: {
            show: true,
        },
        axisBorder: {
            show: true,
            color: '#cacaca',
            height: 1,
            width: '100%',
            offsetX: 0,
            offsetY: 0
        },
        axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#cacaca',
            height: 6,
            offsetX: 0,
            offsetY: 0
        },
    },
    yaxis: {
        show: true,
        showAlways: true,
        labels: {
            show: true,
            minWidth: 0,
            maxWidth: 16,
        },
        axisBorder: {
            show: true,
            color: '#cacaca',
        },
        axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#cacaca',
            width: 6,
            offsetX: 0,
            offsetY: 0
        },
    },
    tooltip: {
        theme: "dark",
        x: {
            show: true,
        },
    },
}