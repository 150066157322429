import defaultLogo from '../../../assets/img/user-profile-default.png';

import './CompetitorMetricsPreview.css';
import {Link} from "react-router-dom";
import {IconPlus} from "@tabler/icons-react";

export default function CompetitorMetricsPreview({id = '', title = '', logo = '', subscribersCount = 0, commentsCount = 0, commentsPercentage = 0, likesCount = 0, likesPercentage = 0, asTitle = false, asAddButton = false}) {

    if (!logo) {
        logo = defaultLogo;
    }


    const formatSubscribersCount = (subscribersCount) => {
        const forms = ['підписник', 'підписника', 'підписників'];
        const cases = [2, 0, 1, 1, 1, 2];
        return subscribersCount + ' ' + forms[(subscribersCount % 100 > 4 && subscribersCount % 100 < 20) ? 2 : cases[(subscribersCount % 10 < 5) ? subscribersCount % 10 : 5]];
    }


    if (asTitle) {
        return (
            <div className="competitor-metrics-preview" style={{marginBottom: '-1rem'}}>
                <div className="competitor-metrics-container">
                    <div className="competitor-metrics-left w-100">
                        <div className="competitor-metrics-progress-value w-100 text-end text-uppercase">Коментарі</div>
                    </div>

                    <div className="competitor-metrics-logo"></div>

                    <div className="competitor-metrics-right w-100">
                        <div className="competitor-metrics-progress-value w-100 text-start text-uppercase">Вподобання</div>
                    </div>
                </div>
            </div>
        );
    }


    if (asAddButton) {
        return (
            <Link to={'/'} className="competitor-metrics-preview">
                <div className="competitor-metrics-container">
                    <div className="competitor-metrics-add-button">
                        <IconPlus size={16} stroke={2} />
                    </div>
                </div>
                <div className="competitor-metrics-title">
                    <div className="text-success">Додати конкурента</div>
                </div>
            </Link>
        );
    }

    return (
        <div className="competitor-metrics-preview">
            <div className="competitor-metrics-container">
                <div className="competitor-metrics-left">
                    <div className="competitor-metrics-progress-value">{commentsCount}</div>
                    <div className="competitor-metrics-progress" style={{width: commentsPercentage + '%'}}></div>
                </div>
                <div className="competitor-metrics-logo">
                    <img src={logo} alt={title}/>
                </div>
                <div className="competitor-metrics-right">
                    <div className="competitor-metrics-progress" style={{width: likesPercentage + '%'}}></div>
                    <div className="competitor-metrics-progress-value">{likesCount}</div>
                </div>
            </div>
            <div className="competitor-metrics-title">
                <div className="">{title}</div>
                <div className="subtitle">{formatSubscribersCount(subscribersCount)}</div>
            </div>
        </div>
    );
}