import React from 'react';


function Footer({}) {


    return (
        <footer>
            <div className="mb-0 text-600">{(new Date()).getFullYear()} &copy; Likengale</div>
        </footer>
    );
}

export default Footer;
