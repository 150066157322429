import React from 'react';
import { IconChartArcs3, IconCalendarStar, IconCoins } from '@tabler/icons-react';


export default function GeneralNav({}) {


    const urlStartsWith = (url) => {
        return window.location.pathname.startsWith(url);
    }
    const urlMatches = (regexp) => {
        return window.location.pathname.match(regexp);
    }


    return (
        <aside className="general-nav">
            <div className="nav-item-cover">
                <div className={'nav-item' + (urlStartsWith('/analytics') ? ' active' : '')}>
                    <a href="/analytics">
                        <IconChartArcs3 stroke={2} size={24}/>
                        Аналіз
                    </a>
                </div>
                <div className={'nav-item' + (urlMatches('\/p(?!romo\/)[^/]*\/.*') ? ' active' : '')}>
                    <a href="/p">
                        <IconCalendarStar stroke={2} size={24}/>
                        План
                    </a>
                </div>
                <div className={'nav-item' + (urlStartsWith('/promo') ? ' active' : '')}>
                    <a href="/promo">
                        <IconCoins stroke={2} size={24}/>
                        Промо
                    </a>
                </div>
            </div>
        </aside>
    );
}