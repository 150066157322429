import React from "react";
import {IconTrendingUp} from "@tabler/icons-react";


export default function BadgePromoted({children, className = '', ...props}) {

    return (
        <div className={'badge badge-promoted ' + className} {...props}>
            <IconTrendingUp size={17} stroke={2} />
            На просуванні
        </div>
    );
}