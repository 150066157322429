import React from "react";
import PublicationsListTabledPlatforms from "./PublicationsListTabledPlatforms";
import PublicationBadges from "../PublicationBadges";
import dayjs from "dayjs";


export default function PublicationsListTabledItem({publications = [], pubDate = '', theme = '', onDeletePublication}) {

    const getPubDateFormatted = () => {
        // format as понеділок, 8 липня if year is same as current year or as понеділок, 8 липня 2020 р. if year is different

        const date = dayjs(pubDate).locale('uk');
        const currentDate = dayjs();

        const year = date.year();
        const currentYear = currentDate.year();

        const formattedDate = date.format('dddd, D MMMM');

        return (year === currentYear) ? formattedDate : `${formattedDate} ${year} р.`;
    }


    return (
        <>
            <div className="custom-responsive-behavior pt-3">
                <div>
                    <div className="publications-list-tabled-publication">
                        <div className="pub-date">{getPubDateFormatted()}</div>
                        <h2>{theme}</h2>
                    </div>
                </div>

                <div className="d-none d-lg-flex"></div>
            </div>
            <div className="custom-responsive-behavior">
                <div>
                    <div className="publications-list-tabled-publication">
                        {publications.length ? (
                            <PublicationBadges
                                className="pub-characteristics"
                                publication={publications[0]}
                            />
                        ) : ''}
                    </div>
                </div>

                <div>
                    <div className="publications-list-tabled-platforms d-flex d-lg-none">
                        <div><h5>&nbsp;</h5></div>
                        <div><h5>Час</h5></div>
                        <div><h5>Медіа</h5></div>
                        <div><h5>Текст</h5></div>
                        <div className="status-field"><h5>Статус</h5></div>
                        <div><h5>&nbsp;</h5></div>
                    </div>

                    {publications ? publications.map(publication => {
                        return (
                            <PublicationsListTabledPlatforms
                                key={publication.id}
                                publication={publication}
                                onDeletePublication={onDeletePublication}
                            />
                        );
                    }) : ''}
                </div>
            </div>
        </>
    );
}