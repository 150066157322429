import React from "react";
import {IconMusic} from "@tabler/icons-react";


export default function BadgePublicationEntertainment({children, className = '', ...props}) {

    return (
        <div className={'badge badge-publication-entertainment ' + className} {...props}>
            <IconMusic size={17} stroke={2} style={{marginTop: '-2px'}} />
            Розважальна
        </div>
    );
}