import React, { useEffect } from 'react';
import { useApi } from "../../../providers/ApiProvider";
import { useNotifications } from "../../../providers/NotificationsProvider";
import ProjectsModel from "../../../models/Projects/ProjectsModel";
import ContentPlansModel from "../../../models/Publications/PublicationPlansModel";
import { useParams } from "react-router-dom";

import Grades from "../../../helpers/Grades";



export default function PublicationPlanViewPlatformProgress({ data }) {
	const { projectId, contentPlanId } = useParams();
	const apiContext = useApi();
	const notificationsContext = useNotifications();
	const projectsModel = new ProjectsModel(apiContext.api);
	const contentPlansModel = new ContentPlansModel(apiContext.api);

	return data ? (
		<div className="progress-stacked w-100 position-relative overflow-visible bg-light mt-3" style={{ height: '4px' }}>
			{data.map(({ type, value }, index) => {
				const colorClassName = Grades.getClassNameByGrade(value);
				const adjustedValue = value > 100 ? 100 : value,
					adjustedValuePercent = Math.round(adjustedValue / data.length * 100) / 100;
				const isNotLastItem = index !== data.length - 1;

				return (
					<div
						key={index}
						className="progress flex-column gap-1"
						role="progressbar"
						aria-valuenow={adjustedValue}
						aria-valuemin="0"
						aria-valuemax="100"
						style={{
							width: adjustedValuePercent + '%',
							minWidth: '16px',
							marginRight: isNotLastItem ? '1px' : '0'
						}}
					>
						<i className={'fs-2 text-' + Grades.getIconClassNameByType(type) + ' ti ti-' + Grades.getIconByType(type) + ' position-absolute bottom-100 pb-1'}></i>
						<div className={'progress-bar progress-bar-striped progress-bar-animated bg-' + colorClassName} style={{ height: '4px' }}></div>
					</div>
				);
			})}
		</div>
	) : '';
}
