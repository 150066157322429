import React from "react";
import {hasGrantedAllScopesGoogle, useGoogleLogin} from '@react-oauth/google';
import {useOutletContext} from "react-router-dom";

import iconGoogle from 'modernize-essentials/dist/images/svgs/google-icon.svg';


const GoogleLogin = (props) => {

    const parentContext = useOutletContext();

    const scopes = [
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email'
    ];

    const onSuccessfulAuth = (codeResponse) => {
        const hasAccess = hasGrantedAllScopesGoogle(
            codeResponse,
            'https://www.googleapis.com/auth/userinfo.profile',
            'https://www.googleapis.com/auth/userinfo.email',
        );

        if (hasAccess) {
            parentContext.onGoogleAuth(codeResponse.code);
        }
    }


    const onClick = useGoogleLogin({
        onSuccess: onSuccessfulAuth,
        flow: 'auth-code',
        scope: scopes.join(' '),
    });


    return (
        <a className="btn btn-white text-dark border w-100" href="#" role="button" onClick={(e) => {e.preventDefault(); onClick();}}>
            <img src={iconGoogle} alt="" className="img-fluid me-2" width="18" height="18" />
            <span className="d-none d-sm-block me-1 flex-shrink-0">Sign in with</span>Google
        </a>
    );
}

export default GoogleLogin;