import React from 'react';

import imgLogo from '../../assets/img/logo.svg';
import HeaderProfile from "./ui/HeaderProfile";
import {Link} from "react-router-dom";
import HeaderSearch from "./ui/HeaderSearch";

export default function Header({}) {


    return (<>
        <header>
            <Link to="/" className="header-logo">
                <img src={imgLogo} alt="Likengale logo" />
            </Link>

            <HeaderSearch />

            <HeaderProfile />
        </header>
    </>);
}