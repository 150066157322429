import React, {useEffect} from 'react';
import {useApi} from "../../../providers/ApiProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsModel from "../../../models/Projects/ProjectsModel";
import {Navigate, useParams} from "react-router-dom";
import PublicationPlansModel from "../../../models/Publications/PublicationPlansModel";
import SimpleModal from "../../ui/SimpleModal";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import {useAuth} from "../../../providers/AuthProvider";
import PublicationsPlanForm from "./edit/PublicationsPlanForm";



export default function PublicationPlanCreateModal({visible = false, onClose = () => {}}) {
    const apiContext = useApi();
    const authContext = useAuth();
    const projectEssentials = authContext.project;
    const publicationPlansModel = new PublicationPlansModel(apiContext.api, projectEssentials.id);
    const notificationsContext = useNotifications();

    const [loading, setLoading] = React.useState(false);
    const [createdPlanId, setCreatedPlanId] = React.useState(null);

    const [startDate, setStartDate] = React.useState('');
    const [theme, setTheme] = React.useState('');


    const createNewPlan = (fields) => {
        if (!fields.date_since || !fields.task_description) {

            notificationsContext.notify('Заповніть всі поля', 'error');
            return;
        }

        setLoading(true);

        const data = {
            project_id: projectEssentials.id,
            date_since: fields.date_since,
            task_description: fields.task_description
        };
        publicationPlansModel.create(data)
            .then((response) => {
                if (response.id) {
                    notificationsContext.notify('План публікацій успішно створено', 'success');
                    setCreatedPlanId(response.id);

                    onClose();
                }
            })
            .catch((error) => {
                console.error('PublicationPlanCreateModal.create', error);

                notificationsContext.notify('Помилка при створенні плану публікацій', 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    }


    return (
        <>
            {createdPlanId && (<Navigate to={'/'+['p', createdPlanId].join('/')} />)}

            <SimpleModal
                visible={visible}
                loading={loading}
                onClose={onClose}
                modalContentClassName={'modal-lg'}
                headerTitle={'Створення нового плану публікацій'}
            >
                <PublicationsPlanForm
                    formData={{date_since: startDate, task_description: theme}}
                    loading={loading}
                    btnSubmit={{
                        className: 'btn-success',
                        label: 'Створити новий план',
                        icon: 'ti-plus'
                    }}
                    onSubmit={createNewPlan}
                />
            </SimpleModal>
        </>
    );
}
