import React from "react";
import SimpleBar from 'simplebar-react';

import imgProfileTmp from 'modernize-essentials/dist/images/profile/user-1.jpg';
import {IconBellRinging} from "@tabler/icons-react";


function HeaderNotifications({}) {

    return (<>
        <a className="position-relative" href="#" id="plan-notifications" data-bs-toggle="dropdown" aria-expanded="false">
            <IconBellRinging size={20} stroke={2} />
        </a>

        <div className="dropdown-menu mt-xl-n7 ms-xl-7" aria-labelledby="plan-notifications">
            <div className="d-flex align-items-center justify-content-between py-3 px-7">
                <h5 className="mb-0 fs-5 fw-semibold">Notifications</h5>
                <span className="badge bg-primary rounded-4 px-3 py-1 lh-sm">5 new</span>
            </div>
            <SimpleBar autoHide={true} className={'message-body'}>
                <a href="#" className="py-6 px-7 d-flex align-items-center dropdown-item">
                    <span className="me-3">
                      <img src={imgProfileTmp} alt="user" className="rounded-circle" width="48" height="48"/>
                    </span>
                    <div className="w-75 d-inline-block v-middle">
                        <h6 className="mb-1 fw-semibold">
                            Roman Joined the Team!
                        </h6>
                        <span className="d-block">Congratulate him</span>
                    </div>
                </a>
                <a href="#" className="py-6 px-7 d-flex align-items-center dropdown-item">
                    <span className="me-3">
                      <img src={imgProfileTmp} alt="user" className="rounded-circle" width="48" height="48"/>
                    </span>
                    <div className="w-75 d-inline-block v-middle">
                        <h6 className="mb-1 fw-semibold">New message</h6>
                        <span className="d-block">Salma sent you new message</span>
                    </div>
                </a>
                <a href="#" className="py-6 px-7 d-flex align-items-center dropdown-item">
                    <span className="me-3">
                        <img src={imgProfileTmp} alt="user" className="rounded-circle" width="48" height="48"/>
                    </span>
                    <div className="w-75 d-inline-block v-middle">
                        <h6 className="mb-1 fw-semibold">
                            Bianca sent payment
                        </h6>
                        <span className="d-block">Check your earnings</span>
                    </div>
                </a>
                <a href="#" className="py-6 px-7 d-flex align-items-center dropdown-item">
                    <span className="me-3">
                        <img src={imgProfileTmp} alt="user" className="rounded-circle" width="48" height="48"/>
                    </span>
                    <div className="w-75 d-inline-block v-middle">
                        <h6 className="mb-1 fw-semibold">
                            Jolly completed tasks
                        </h6>
                        <span className="d-block">Assign her new tasks</span>
                    </div>
                </a>
                <a href="#" className="py-6 px-7 d-flex align-items-center dropdown-item">
                    <span className="me-3">
                        <img src={imgProfileTmp} alt="user" className="rounded-circle" width="48" height="48"/>
                    </span>
                    <div className="w-75 d-inline-block v-middle">
                        <h6 className="mb-1 fw-semibold">
                            John received payment
                        </h6>
                        <span className="d-block">$230 deducted from account</span>
                    </div>
                </a>
                <a href="#" className="py-6 px-7 d-flex align-items-center dropdown-item">
                    <span className="me-3">
                        <img src={imgProfileTmp} alt="user" className="rounded-circle" width="48" height="48"/>
                    </span>
                    <div className="w-75 d-inline-block v-middle">
                        <h6 className="mb-1 fw-semibold">
                            Roman Joined the Team!
                        </h6>
                        <span className="d-block">Congratulate him</span>
                    </div>
                </a>
            </SimpleBar>
            <div className="py-6 px-7 mb-1">
                <button className="btn btn-outline-primary w-100">
                    See All Notifications
                </button>
            </div>
        </div>
    </>)
}

export default HeaderNotifications;