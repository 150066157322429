import React from "react";
import CardLoading from "./CardLoading";

function SimpleCardContainer({loading, title, titleRight, children, className = '', cardBodyClassName = 'p-0'}) {
    return (
        <div className={'card w-100 position-relative '+className+(loading ? ' loading' : '')}>
            {title && (
            <div className="px-0 py-3 border-bottom d-flex align-items-center justify-content-between">
                <h5 className="align-items-center card-title d-flex fw-semibold gap-2 lh-sm mb-0">{title}</h5>

                {titleRight && (
                    <div className="d-flex align-items-center">
                        {titleRight}
                    </div>
                )}
            </div>
            )}
            <div className={'card-body '+cardBodyClassName}>
                {children}
            </div>

            <CardLoading loading={loading} />
        </div>
    )
}

export default SimpleCardContainer;