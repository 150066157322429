import React from 'react';
import {Link} from "react-router-dom";
import SimpleBar from "simplebar-react";


export default function SectionNavHeader({}) {


    const urlStartsWith = (url) => {
        return window.location.pathname.startsWith(url);
    }
    const urlMatches = (regexp) => {
        return window.location.pathname.match(regexp);
    }


    return (
        <SimpleBar autoHide={true} className="top-level">
            <div className="top-level-content">
                <Link to="/p" className={(urlMatches('\/p(?!romo\/|\/tasks|\/competitors).*') ? ' active' : '')}>Плани публікацій</Link>
                <Link to="/p/tasks" className={(urlStartsWith('/p/tasks') ? ' active' : '')}>Завдання на місяць</Link>
                <Link to="/p/competitors" className={(urlStartsWith('/p/competitors') ? ' active' : '')}>Конкуренти за планом</Link>
            </div>
        </SimpleBar>
    );
}