import React, {useEffect} from "react";

import ButtonWithLoading from "../../../layout/ui/ButtonWithLoading";
import SimpleCardContainer from "../../../ui/SimpleCardContainer";
import FormDateField from "../../../layout/ui/FormDateField";
import FormTextArea from "../../../layout/ui/FormTextArea";


export default function PublicationsPlanForm({formData = {}, loading = true, btnSubmit = {className: 'btn-success', label: 'Створити новий план', icon: 'ti-plus'}, onSubmit}) {

    const [startDate, setStartDate] = React.useState('');
    const [theme, setTheme] = React.useState('');
    const [errors, setErrors] = React.useState({});


    useEffect(() => {
        if (formData.date_since) {
            setStartDate(formData.date_since);
        } else {
            setStartDate('');
        }

        if (formData.task_description) {
            setTheme(formData.task_description);
        } else {
            setTheme('');
        }
    }, [formData]);


    const validateForm = () => {
        let errors = {};
        if (!startDate) {
            errors.date_since = ['Це обов\'язкове поле'];
        }
        if (!theme) {
            errors.task_description = ['Це обов\'язкове поле'];
        }

        setErrors(errors);

        return Object.keys(errors).length === 0;
    }


    const onBtnSubmit = () => {
        if (!validateForm()) {return;}

        const data = {
            date_since: startDate,
            task_description: theme
        }

        onSubmit(data);
    }


    return (<>
        <div style={{maxWidth: '300px'}}>
            <FormDateField
                label={(<>Дата початку <span className="text-danger">*</span></>)}
                name="date_since"
                value={startDate}
                onChange={setStartDate}
                required={true}
                groupClassName="mb-3"
                errors={errors}
            />
        </div>

        <FormTextArea
            label={(<>Тема та нотатки <span className="text-danger">*</span></>)}
            name="task_description"
            value={theme}
            onChange={setTheme}
            required={true}
            groupClassName="mb-4"
            errors={errors}
        />

        <div className="form-group">
            <ButtonWithLoading
                className={'btn ' + btnSubmit.className}
                loading={loading}
                label={btnSubmit.label}
                noOriginalIconClassName={true}
                iconClassName={'ti fs-4 me-2 ' + btnSubmit.icon}
                onClick={onBtnSubmit}
            />
        </div>
    </>);
}