import React from 'react';

import Header from "./Header";
import Footer from "./Footer";
import GeneralNav from "./GeneralNav";

const MainLayout = (props) => {
    return (
        <>
            <Header />

            <GeneralNav />

            <div className="body-wrapper">
                {props.children}
            </div>

            <Footer />
        </>
    );
}

export default MainLayout;
