import React, {useEffect} from 'react';

import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import FormTextField from "../../layout/ui/FormTextField";
import FormTextArea from "../../layout/ui/FormTextArea";
import FormCheckbox from "../../layout/ui/FormCheckbox";
import FormSingleImage from "../../layout/ui/FormSingleImage";
import FormDateField from "../../layout/ui/FormDateField";

export default function ProjectTasksForm({formData, onSubmit, errors, loading, isNewRecord, className = ''}) {
    const [data, setData] = React.useState({});


    useEffect(() => {
        setData(formData);
    }, [formData]);


    const updateField = (field, value) => {
        let newData = {...data};
        newData[field] = value;

        setData(newData);
    }


    const onSubmitClick = (e) => {
        e.preventDefault();

        onSubmit(data);
    }


    return (
        <form className={className}>
            <FormDateField
                label={'Дата закінчення'}
                name={'date_for'}
                errors={errors}
                onChange={(value) => {
                    updateField('date_for', value)
                }}
                value={data.date_for}
                required={true}
                groupClassName={'mb-3'}
            />

            <FormTextArea
                label={'Опис завдання'}
                name={'description'}
                errors={errors}
                onChange={(value) => {
                    updateField('description', value)
                }}
                value={data.description}
                groupClassName={'mb-3'}
            />

            <div className="row g-3 mb-3 mt-4">
                <div className="col-lg-4">
                    <h5 className="mb-3">Instagram</h5>

                    <FormTextField
                        label={'Публікації у плані, шт'}
                        name={'plan_publications_instagram'}
                        errors={errors}
                        onChange={(value) => {
                            updateField('discipline_rules', {
                                ...data.discipline_rules,
                                instagram: {...data.discipline_rules.instagram, posts_number: parseInt(value)}
                            })
                        }}
                        value={data.discipline_rules && data.discipline_rules.instagram ? data.discipline_rules.instagram.posts_number : ''}
                        groupClassName={'mb-3'}
                    />

                    <FormTextField
                        label={'Метрика «Видимість», %'}
                        name={'kpi_instagram_visibility'}
                        errors={errors}
                        onChange={(value) => {
                            updateField('kpi', {...data.kpi, instagram: {...data.kpi.instagram, visibility: value}})
                        }}
                        value={data.kpi && data.kpi.instagram ? data.kpi.instagram.visibility : ''}
                        groupClassName={'mb-3'}
                    />

                    <FormTextField
                        label={'Метрика «Ефективність», %'}
                        name={'kpi_instagram_er'}
                        errors={errors}
                        onChange={(value) => {
                            updateField('kpi', {...data.kpi, instagram: {...data.kpi.instagram, er: value}})
                        }}
                        value={data.kpi && data.kpi.instagram ? data.kpi.instagram.er : ''}
                    />
                </div>
                <div className="col-lg-4">
                    <h5 className="mb-3">Facebook</h5>

                    <FormTextField
                        label={'Публікації у плані, шт'}
                        name={'plan_publications_facebook'}
                        errors={errors}
                        onChange={(value) => {
                            updateField('discipline_rules', {
                                ...data.discipline_rules,
                                facebook: {...data.discipline_rules.facebook, posts_number: parseInt(value)}
                            })
                        }}
                        value={data.discipline_rules && data.discipline_rules.facebook ? data.discipline_rules.facebook.posts_number : ''}
                        groupClassName={'mb-3'}
                    />

                    <FormTextField
                        label={'Метрика «Видимість», %'}
                        name={'kpi_facebook_visibility'}
                        errors={errors}
                        onChange={(value) => {
                            updateField('kpi', {...data.kpi, facebook: {...data.kpi.facebook, visibility: value}})
                        }}
                        value={data.kpi && data.kpi.facebook ? data.kpi.facebook.visibility : ''}
                        groupClassName={'mb-3'}
                    />

                    <FormTextField
                        label={'Метрика «Ефективність», %'}
                        name={'kpi_facebook_er'}
                        errors={errors}
                        onChange={(value) => {
                            updateField('kpi', {...data.kpi, facebook: {...data.kpi.facebook, er: value}})
                        }}
                        value={data.kpi && data.kpi.facebook ? data.kpi.facebook.er : ''}
                    />
                </div>
                <div className="col-lg-4">
                    <h5 className="mb-3">Історії Instagram</h5>

                    <FormTextField
                        label={'Публікації у плані, шт'}
                        name={'plan_publications_stories'}
                        errors={errors}
                        onChange={(value) => {
                            updateField('discipline_rules', {
                                ...data.discipline_rules,
                                stories: {...data.discipline_rules.stories, posts_number: parseInt(value)}
                            })
                        }}
                        value={data.discipline_rules && data.discipline_rules.stories ? data.discipline_rules.stories.posts_number : ''}
                        groupClassName={'mb-3'}
                    />

                    <FormTextField
                        label={'Метрика «Видимість», %'}
                        name={'kpi_stories_visibility'}
                        errors={errors}
                        onChange={(value) => {
                            updateField('kpi', {...data.kpi, stories: {...data.kpi.stories, visibility: value}})
                        }}
                        value={data.kpi && data.kpi.stories ? data.kpi.stories.visibility : ''}
                        groupClassName={'mb-3'}
                    />

                    <FormTextField
                        label={'Метрика «Ефективність», %'}
                        name={'kpi_stories_er'}
                        errors={errors}
                        onChange={(value) => {
                            updateField('kpi', {...data.kpi, stories: {...data.kpi.stories, er: value}})
                        }}
                        value={data.kpi && data.kpi.stories ? data.kpi.stories.er : ''}
                    />
                </div>
            </div>

            <div className={'form-group mt-4'}>
                <ButtonWithLoading
                    className={isNewRecord ? 'btn-success' : 'btn-primary'}
                    label={isNewRecord ? 'Додати нове завдання' : 'Зберегти зміни'}
                    loading={loading}
                    noOriginalIconClassName={true}
                    iconClassName="d-none"
                    onClick={onSubmitClick}
                />
            </div>
        </form>
    );
}
